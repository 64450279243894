/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
/* eslint no-mixed-spaces-and-tabs: 0 */

import React, { useState, useRef,useEffect } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import WheelForm from './wheelForm'
import {verifyPromo,fetchPromoItems,promoWin,promoUserWin} from '../Api'
import WheelComponent from 'react-wheel-of-prizes'
import { useNavigate,Link } from 'react-router-dom';
// import Navbar from './components/navbar'
export default function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const phone = urlParams.get('phone');
  const package_id = urlParams.get('package_id');

  const navigate = useNavigate();
  let [flag, setFlag] = useState(false);
  const [msg,setMsg]=useState('')
  const [error,setError]=useState(false)
  const confetiRef = useRef(null);
  // const { phone,package_id } = useParams();
  const { width, height } = useWindowSize();
const [items,setItems]=useState([])
const [weights,setWeights]=useState([])
  // const [loading, setLoading] = useState([]);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState('');
  const [list,setList]=useState([])
  const [loose,setLoose]=useState(false)
  
  const spinClicked = (value) => {
    setFlag(value);
  };

  const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000',
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000'
  ]
  const onFinished = (winner) => {
    postWin(winner)
 
var foundObjects = list.filter(function(obj) {
  return obj.name === winner;
});

 if(foundObjects[0].win==true){
  spinClicked(true)
 }else{
  setLoose(true);
 }

  }
  const getPromoItems= ()=>{
    try {
     //setLoading(true)
    fetchPromoItems().then(data=>{
   
    var i=data.map(item => item.name);
    var w=data.map(item => item.odds);
 
   setList(data)
    setItems(i)
    setWeights(w)
    spinWheelOperation(i,w);

    })
    
       //setLoading(false)
   } catch (error) {
     //setLoading(false)
     console.log(error);
   }
 }
  useEffect(()=>{

    verifyPromo({phone:phone.toString(),package_id:package_id.toString()}).then((msg)=>{
      if(msg.result==false){
        setMsg(msg.msg)
        setError(true)
    setTimeout(()=>{
      navigate('/')
// cpo
    },5000)
      }
      
        
     })
     fetchPromoItems().then(data=>{
   
      var i=data.map(item => item.name);
      var w=data.map(item => item.odds);
      // console.log(data)
       setList(data)
      setItems(i)
      setWeights(w)
      spinWheelOperation(i,w);

      })
  //  getPromoItems()
     },[])

  const createDistribution = (weights2, size) => {

    weights2=weights2.map(floatNum => {
      const parsedFloat = parseFloat(floatNum);
      return Math.floor(parsedFloat);
    });
   
    const distribution = [];
    
    const sum = weights2.reduce((a, b) => a + b);
    const quant = size / sum;
  	for (let i = 0; i < weights2.length;++i) {
      	const limit = quant * weights2[i];
      	for (let j = 0; j < limit; ++j) {
          	distribution.push(i);
        }
    }
  	return distribution;
};

const randomIndex = (distribution) => {
  	const index = Math.floor(distribution.length * Math.random());  // random index
  
  
    return distribution[index];  
};

const randomItem = (array, distribution) => {
    const index = randomIndex(distribution);
   return array[index];
};



const spinWheelOperation=(i,w)=>{
  let intervalId;
  function startInterval() {
    intervalId = setInterval(() => {
      if (i.length>0 && w.length>0) {
         clearInterval(intervalId); 
          const distribution = createDistribution(w, 10000); 
var r= randomItem(i, distribution)
   setResult(r)
 
      } else{
        getPromoItems()
      }
   
    }, 1000); 
  }
     startInterval();

}
const postWin=(r)=>{

    try {
     //setLoading(true)
     promoWin(
  {
    "phone":phone,
    "package_id":package_id,
    "item_won":r,
     "item_won_id":list.find(item => item.name === r).id,
     "reward_status":list.find(item => item.name === r).win==true ?'won':'nowin'
}).then(()=>{
   
    // console.log(data)
 
    })
  


    
       //setLoading(false)
   } catch (error) {
     //setLoading(false)
     console.log(error);
   }

}



  return (
    <div className="body" style={{display:'flex',flexDirection:'column',alignItems:'center',height:'100vh',justifyContent:'start',width:'100%',
    background: 'url("../../images/backimg.webp")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow:'hidden'}}>
      <div style={{height:50,color:'orange',paddingLeft:10,lineHeight:'50px',fontSize:28,fontWeight:'bold',background:'white',width:'100%'}}>
        <span><img alt='img' src={'../../images/logo3.png'} height='30px' /></span>
      </div>
      <div className="confettie-wrap" ref={confetiRef}>
        <Confetti
          run={flag}
          numberOfPieces={150}
          width={width}
          height={height}
        />
      </div>
      <div style={{padding:10,fontSize:24}}>  Spin and win</div>
     
      <div style={{width:800,zoom:0.6,paddingLeft:400, display:'flex',flexDirection:'row',justifyContent:'center',overflow:'hidden',fontSize:24}}>
 
     {items.length>0 && weights.length>0 && result!='' && <WheelComponent
      segments={items}
      segColors={segColors}
      winningSegment={result}
      spinDuration={10000} numberOfSpins={3} 
      onFinished={(winner) => {onFinished(winner);}}
      primaryColor='black'
      contrastColor='white'
      buttonText='Spin'
      isOnlyOnce={true}
      size={290}
      upDuration={1500}
      downDuration={15000}
      fontFamily='Arial'
    />}
    </div>
      {/* <br />
      <br /> */} 
      {flag ? (
        <WheelForm promoUserWin={promoUserWin} phone2={phone} setSuccess={setSuccess} package_id={package_id}/>
      
      ) : (
        <div> </div>
      )}
{/* <button onClick={()=>{
  spinWheelOperation(items,weights)
}}>button</button> */}
      {error && <div style={{width:'100%',height:'100vh',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>\
    
      <div style={{background:'white',borderRadius:10,padding:10,textAlign:'center'}}>
  {msg}<br/>
<Link to='../../'>Back to Homepage</Link>
      </div>
      </div>}
      {success && <div style={{width:'100%',height:'100vh',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>\
    
    <div style={{background:'white',borderRadius:10,padding:10,textAlign:'center'}}>
    
    <div>
      </div>
      <img alt='img'  src={'../../images/prize.png'} height='60px' />
    <h3>   Prize has been awaded</h3>
 

<br/>
<Link to='../../'>Back to Homepage</Link>
    </div>
    </div>}
    {loose && <div style={{width:'100%',height:'100vh',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>\
    
    <div style={{background:'white',borderRadius:10,padding:10,textAlign:'center'}}>
    
    <div>
      </div>
      
    <h3> Better luck next time!</h3>
 

<br/>
<Link to='../../'>Back to Homepage</Link>
    </div>
    </div>}

    </div>
  );
}
