/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import '../App.css';
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { useParams } from 'react-router-dom';
import { fetchOneArticles_notice,fetchComments } from '../Api';
import CommentSection from './comment';
import { Helmet } from 'react-helmet';
import EventApplication from './eventApplication'
const Community = () => {
  // const location = useLocation();

  // const baseUrl = location.pathname.split('/')[0];
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [idComment,setIdComment]=useState('')
  const [loading, setLoading] = useState(null);
  const [comment, setComment] = useState([]);
  const getComments= async(i)=>{
    try {
     setLoading(true)
     const data = await fetchComments(i);
     setComment(data);
    //  console.log(data);
       setLoading(false)
   } catch (error) {
     setLoading(false)
     console.log(error);
   }
 }
 useEffect(() => {
  window.scrollTo(0, 0);

 },[])
  useEffect(() => {
 
    setLoading(true)
        const getOneArticle= async()=>{
         try {
        
         fetchOneArticles_notice(id).then((data)=>{
          setData(data);
        //   console.log(data);
          getComments(data.id)
          setIdComment(data.id)
            setLoading(false)

          })

        } catch (error) {
          setLoading(false)
          console.log(error);
        }
      }
      getOneArticle()
    
   
  }, [id]);
  const [url, setUrl] = useState('')
  const [modal, setModal] = useState(false)
  return (
    <div style={{padding:10,display:'flex',flexDirection:'column',alignItems:'center',width:'100%',minHeight:'60vh'}}>
      {/* <h4>Community</h4> */}
       <Helmet>
  {}<title> {'Karibu Konnect | '+id}</title>
  <meta name="description" content={data?.title}/>
</Helmet>
 
      {loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'../images/loader.gif'}  height={80}  />
  </div>
    }
{data?.title!=null &&  <div className="row_class"  style={{width:'100%',justifyContent:'center',flexDirection:'column',maxWidth:800}}>
    <div style={{flex:3,minWidth:340,position:'relative'}}>    
     <h5 className="card-title " style={{color:'orange',fontSize:24}}>{data?.title}</h5>
  {data?.category!="announcement" &&  <div>
  <small style={{color:'gray'}}> 


 
  {new Date(data.date_created.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })} {' '}
  {data.date_created.substring(11,16)}
  {' by '} 
 </small> <small style={{color:'#0D47A1'}}>  {data.author ?? data.user.name}</small>   </div>}
{ data.main_image!=null && <div style={{width:'100%',justifyContent:'center',display:'flex'}}>
      <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +data.main_image} className="card-img-top" alt="..." 
      
    //   style={{width:'25vw',minWidth:200}}
      />  </div>
}
  <div  onClick={()=>{setModal(true); setUrl('https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +data.main_image)}}  style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>
  </div>
  <small style={{color:'gray'}}>  {data.main_image_caption}</small>
  </div>
  <div className="card-body" style={{flex:2,minWidth:200}}>
 
    <div  style={{fontSize:14,paddingTop:0,lineHeight:'25px'}}>
    

       {ReactHtmlParser(data.main_section_msg) }
     </div>
    {/* <Link to="#" className="btn btn-outline-primary btn-outline">Read More</Link> */}
  </div>
</div>}

{data?.title!=null && data.sections.map((item,idx)=>{
  return(<div key={idx}   style={{width:'100%',justifyContent:'center',flexDirection:'column',maxWidth:800}}>
    <div style={{flex:3,minWidth:340,position:'relative'}}>   

  {/* <small style={{color:'gray'}}> Date Created: {item.date_created}</small> */}
{item.image!='' && <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.image}  className="card-img-top" alt="..."/>}
  {/* <small style={{color:'gray'}}> Author: {item.author}</small> */}
  <div onClick={()=>{setModal(true); setUrl('https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.image)}}  style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>
  </div>
  <small style={{color:'gray'}}>  {data.image_caption}</small>
  </div>
  <div className="card-body" style={{flex:2,minWidth:200}}>

 
    <div  style={{fontSize:14,paddingTop:0,lineHeight:'25px'}}>
       {ReactHtmlParser(item.msg) }
    
    </div>
 </div>
</div>)
})}
<a href='https://cdn.sasakonnect.net/landing-page/1a4c39d6-f57a-4cde-9ec6-a907dca73606?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=9nCsrFAhd244QrkAugDZ%2F20240102%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240102T164950Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=90616e01426f9c5e423b67a877784f84c4709ab0c821a8a381ae7c285c810e96' download><button className='btn btn-outline-primary'>Download Letter</button> </a>

 {loading==false &&  data?.category!='promotedevent'  &&<CommentSection getComments={getComments} comments={comment} id={idComment} title={data?.title} likes={data?.likes} slug={data?.slug}/>}

{data?.category=='promotedevent' && <EventApplication   title={data?.slug} />}


{modal && <div style={{width:'100vw',zIndex:40,height:'100vh',background:'rgba(0,0,0,0.7)',position:'fixed',top:0,left:0,display:'flex',alignItems:"center",justifyContent:'center'}}>
     <div onClick={()=>{setModal(!modal)}} style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}></div>
     <div style={{width:'95%',maxWidth:1000,minWidth: 360,zIndex:51,position:'relative'}}>
 <img src={url} width='100%' />
 <div style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

  </div>
      </div>
    </div>}
    </div>
  );
};

export default Community;