/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import '../App.css';
import React, { useState, } from 'react';
import KonnectYoutube from './konnect_youtube'
import KonnectJikoni from './konnect_jikoni'
const Article = () => {

const [selected,setSelected]=useState(1)


  return (
 <div style={{flex:1,paddingTop:0}}>

<br/><br/>

    <div style={{borderBottom:'0px solid red',display:'flex',flexDirection:'row',textAlign:'center',cursor:'pointer',boxShadow:"0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)"}}>
      <div onClick={()=>{setSelected(1)}} style={{padding:5,lineHeight:'20px',color:selected==1?'orange':'black',borderBottom:selected==1?'3px solid orange':'white',background:selected==1?'white':'white',fontWeight:selected==1?'bold':'normal',cursor:'pointer'}}>Konnect Food+</div>
      <div onClick={()=>{setSelected(2)}} style={{padding:5,lineHeight:'20px',color:selected==2?'orange':'black',background:selected==2?'white':'white',fontWeight:selected==2?'bold':'normal',cursor:'pointer',borderBottom:selected==2?'3px solid orange':'white'}}>Karibu Konnect</div>
    </div>
<div style={{width:'100%',display:selected==1 ? 'block':'none'}}><KonnectJikoni show={false}/></div>
    <div style={{width:'100%',display:selected==2 ? 'block':'none'}}><KonnectYoutube show={false}/></div>
 
 
    </div>

  );
};

export default Article;