import SurveyView from './surveyView'
import { useParams } from 'react-router-dom';
import React from 'react'
import Navbar from '../navbar'
import Footer from '../footer'
  const SurveyCreatorWidget=()=> {
    const { id } = useParams();
  
  
  
  return (
    <div>
      <Navbar/>
      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <div style={{width:'80%',maxWidth:800,minWidth:360}}>
          <SurveyView  id={id} />
        </div>
      </div>
<Footer/>
    </div>
  
  )
}
export default SurveyCreatorWidget