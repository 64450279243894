import React,{useState} from 'react'
import {postEventApplication_2} from '../Api'
export default function eventApplication(props) {
    const [name, setName] = useState('');
   const [regionOthers, setRegionOthers] = useState('');
    const [phone, setPhone] = useState('');
    const [loading,setLoading]=useState(false)
    const [success,setSuccess]=useState(false)
    const [region, setRegion] = useState('');
    const [open_quiz_2, setOpen_quiz_2] = useState('');
     const [open_quiz_1, setOpen_quiz_1] = useState('');
    // const [open_quiz_1_other, setOpen_quiz_1_other] = useState('');
    const [open_quiz_3, setOpen_quiz_3] = useState('');
    // const [open_quiz_3_other, setOpen_quiz_3_other] = useState('');
    const [open_quiz_4, setOpen_quiz_4] = useState('');
    const [open_quiz_5, setOpen_quiz_5] = useState('');
    const [open_quiz_6, setOpen_quiz_6] = useState('');
    const [open_quiz_7, setOpen_quiz_7] = useState('');
    const [open_quiz_8, setOpen_quiz_8] = useState('');
    const [open_quiz_9, setOpen_quiz_9] = useState('');
    const [open_quiz_10, setOpen_quiz_10] = useState('');

    // const handleCheckboxChange = (setFunc) => (event) => {
    //   const { value, checked } = event.target;
    //   setFunc((prev) =>
    //     checked ? [...prev, value] : prev.filter((item) => item !== value)
    //   );
    // };
  
    // const handleRadioChange = (setFunc) => (event) => {
    //   setFunc(event.target.value);
    // };
  
    // const handleOtherChange = (setFunc) => (event) => {
    //   setFunc(event.target.value);
    // };
  
  // const [open_quiz_3, setOpen_quiz_3] = useState('');

  // const [selectedClasses, setSelectedClasses] = useState([]);

  // const handleCheckboxChange = (e) => {
  //   const selectedClasses2 = e.target.value;
  //   if (e.target.checked) {
  //     setSelectedClasses([...selectedClasses, selectedClasses2]);
  //   } else {
  //     setSelectedClasses(selectedClasses.filter(pkg => pkg !== selectedClasses2));
  //   }
  // };

    // const [message_quiz_1, setMessage_quiz_1] = useState('');
    // const [message_quiz_2, setMessage_quiz_2] = useState('');
    // const [message_quiz_3, setMessage_quiz_3] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
            setLoading(true)
          var data=  {
                "title": props.title,
                "phone": phone,
                "name": name,
                        "residency": region,
                "residency_other": regionOthers,
                  "open_quiz_1": open_quiz_1,
                //   open_quiz_1.includes('Other') ? [...open_quiz_1.filter(option => option !== 'Other'), `Other: ${open_quiz_1_other}`].join(',') : open_quiz_1.join(' & '),
 
            "open_quiz_2": open_quiz_2,

 "open_quiz_4": open_quiz_4,
  "open_quiz_3": open_quiz_3,
    // "open_quiz_4": open_quiz_4.join(' & '),
  "open_quiz_5": open_quiz_5,
//   .join(' & '),
  "open_quiz_6":open_quiz_6.toString(),
  "open_quiz_7":open_quiz_7,
  "open_quiz_8":open_quiz_8,
  "open_quiz_9":open_quiz_9,
  "open_quiz_10":open_quiz_10,
//   "message_quiz_1": message_quiz_1,
  // "message_quiz_2": message_quiz_2
                          }
                          console.log(data)
                          postEventApplication_2(data).then((msg)=>{
        setName('')
        setRegionOthers('')
        setPhone('')
        setRegion('')
        setRegionOthers('')
      setOpen_quiz_2('')
         setOpen_quiz_1('')
        //  setOpen_quiz_1('')
        //  setOpen_quiz_2('')
        //  setOpen_quiz_1('')

          setOpen_quiz_4('') 
          setOpen_quiz_3('')
          // setOpen_quiz_4('')
          setOpen_quiz_5('')
          setOpen_quiz_6('')
          setOpen_quiz_7('')
          setOpen_quiz_8('')
          setOpen_quiz_9('')
          setOpen_quiz_10('')

        //  setOpen_quiz_3('')
        // setMessage_quiz_1('')
        // setOpen_quiz_1_other('')
        // setOpen_quiz_3_other('')
        // setMessage_quiz_2('')
        // setMessage_quiz_3('')

        setLoading(false)
        if(msg!=false){
            // handleButtonClick()
            setSuccess(true)
            // window.scrollTo(0, 0);
            // props.sentData(true)
        }
       

      
       })
     }
    //  const [showDiv, setShowDiv] = useState(false);
    //  const handleButtonClick = () => {

    //     setShowDiv(true);
    //         setTimeout(()=>{
    //       setShowDiv(false);
    //     },3000)
        
    //   }
    const [showTerm,setShowTerms]=useState(false)
  return (
    <div style={{width:'100%',maxWidth:800,backgroundColor:!success?'#e5e5e5':'white',padding:10,borderRadius:10}}>

<div style={{position:'fixed',width:'100%',left:0,top:100,display:'flex',flexDirection:'row',justifyContent:'center',zIndex:1000}}>

{/* {showDiv && (
    <div className={`alert alert-success slide-in ${showDiv ? 'show' : ''}`} role="alert">
        Application submitted successfully
   </div>

      )} */}
      </div>
      {success==false  && <form onSubmit={handleSubmit}>


     
        <h4>Apply to Participate </h4>
        {/* <div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div> */}

                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {/* <div className="form-group" style={{flex:1,minWidth:150}}>
                  <label htmlFor="phone" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={phone}  onChange={(e)=>{setPhone(e.target.value)}}  className="form-control" id="phone" placeholder="Enter your phone number"  />
                </div> */}
             
                </div>
            


    


    

<div className="form-group">
  <label htmlFor="region" className="required">   Region</label>
  <select required className="form-control" id="region" value={region} onChange={(e)=>{
    setRegion(e.target.value);
  }}>


<option value=''>-- --</option>
{[
      ['Githurai_44', 'Githurai 44'],
    ['Githurai_45', 'Githurai 45'],
    ['Roysambu/Mirema', 'Roysambu/Mirema'],
    ['Zimmerman', 'Zimmerman'],
    ['Kahawa_West', 'Kahawa West'],
    ['Kahawa_Wendani', 'Kahawa Wendani'],
    ['Kasarani', 'Kasarani'],   
    ['Hunters/Mwiki/Sunton', 'Hunters/Mwiki/Sunton'],
    ['Lucky_Summer/Baba_Dogo/Ngumba', 'Lucky Summer/Baba Dogo/Ngumba'],
    ['kariobangi', 'Kariobangi'],
    ['Mowlem_Umoja_3', 'Mowlem/Umoja 3'],
    ['Umoja', 'Umoja'],
    ['Pipeline', 'Pipeline'],
    ['Kibera', 'Kibera']
  ].map(([value, label]) => (
    <option key={value} value={value}>{label}</option>
  ))}


    <option value="Other">Other</option>

  </select>
</div>
{region==='Other'     && <div className="form-group">
 <label htmlFor="regionOthers" >Specify residency if `Other` is selected

                  </label>
                  <input  value={regionOthers} onChange={(e)=>{setRegionOthers(e.target.value)}} type="text" className="form-control" id="regionOthers" placeholder=""  />
                </div>}
                <div className="form-group">
      <label htmlFor="parentName" className="required">Name of Parents</label>
      <input
        value={open_quiz_1}
        onChange={(e) => setOpen_quiz_1(e.target.value)}
        type="text"
        className="form-control"
        id="parentName"
        required
        placeholder="Enter parent's name"
      /></div>
    <div className="form-group">
      <label htmlFor="parentPhone" className="required">Phone Number of Parents</label>
      <input
        value={open_quiz_2}
        onChange={(e) => setOpen_quiz_2(e.target.value)}
        type="tel"
        className="form-control"
        id="parentPhone"
        required
        placeholder="Enter parent's phone number"
      /></div>
      
 <div style={{width:'100%',display:'flex',gap:10}}>
  {/* 1st Child */}
  <div className="form-group flex-child" style={{flexBasis:'45%'}}>
    <label htmlFor="childName1" className="required">Name of 1st Child</label>
    <input
      value={open_quiz_3}
      onChange={(e) => setOpen_quiz_3(e.target.value)}
      type="text"
      className="form-control"
      required
      id="childName1"
      placeholder="Enter name of 1st child"
    />
  </div>

  <div className="form-group flex-child" style={{flexBasis:'45%'}}>
    <label htmlFor="childAge1" className="required">Age of 1st Child</label>
    <input
      value={open_quiz_4}
      onChange={(e) => setOpen_quiz_4(e.target.value)}
      type="number"
      className="form-control"
      required
      id="childAge1"
      placeholder="Enter age of 1st child"
    />
  </div>
</div>

<div style={{width:'100%',display:'flex',gap:10}}>
  {/* 2nd Child */}
  <div className="form-group flex-child" style={{flexBasis:'45%'}}>
    <label htmlFor="childName2">Name of 2nd Child (Optional)</label>
    <input
      value={open_quiz_7}
      onChange={(e) => setOpen_quiz_7(e.target.value)}
      type="text"
      className="form-control"
      id="childName2"
      placeholder="Enter name of 2nd child"
    />
  </div>

  <div className="form-group flex-child" style={{flexBasis:'45%'}}>
    <label htmlFor="childAge2">Age of 2nd Child (Optional)</label>
    <input
      value={open_quiz_8}
      onChange={(e) => setOpen_quiz_8(e.target.value)}
      type="number"
      className="form-control"
      id="childAge2"
      placeholder="Enter age of 2nd child"
    />
  </div>
</div>

<div style={{width:'100%',display:'flex',gap:10}}>
  {/* 3rd Child */}
  <div className="form-group flex-child" style={{flexBasis:'45%'}}>
    <label htmlFor="childName3">Name of 3rd Child (Optional)</label>
    <input
      value={open_quiz_9}
      onChange={(e) => setOpen_quiz_9(e.target.value)}
      type="text"
      className="form-control"
      id="childName3"
      placeholder="Enter name of 3rd child"
    />
  </div>

  <div className="form-group flex-child" style={{flexBasis:'45%'}}>
    <label htmlFor="childAge3">Age of 3rd Child (Optional)</label>
    <input
      value={open_quiz_10}
      onChange={(e) => setOpen_quiz_10(e.target.value)}
      type="number"
      className="form-control"
      id="childAge3"
      placeholder="Enter age of 3rd child"
    />
  </div>
</div>

<div className="form-group">
      <label htmlFor="emergencyContact" className="required">Emergency Contact</label>
      <input
        value={open_quiz_5}
        onChange={(e) => setOpen_quiz_5(e.target.value)}
        type="tel"
        required
        className="form-control"
        id="emergencyContact"
        placeholder="Enter emergency contact number"
      />
    </div>
    <div className="form-group">
 
  <input
    type="checkbox"
    checked={open_quiz_6}
    onChange={(e) =>{ setOpen_quiz_6(e.target.checked);setShowTerms(!showTerm)}}
    // className="form-check-input"
    id="checkboxExample"
    required
  /> <label htmlFor="checkboxExample">I agree to the <b>terms and conditions</b></label>
 {showTerm && <p>I understand that my child`s photos/videos taken during the kids party maybe used for marketing purposes. I give consent as a parent.</p>}
</div>
            
    <div style={{float:'right'}}>
 {loading ?<button type="button" className="btn btn-warning"
             
           
             >Loading...</button>: <button type="submit" className="btn btn-warning"
             
           
             >Submit</button>}
    </div>
 

               </form>}
         
               {success && (
    <div className={`alert alert-success`} role="alert">
Thank you for submitting your Application.
   </div>

      )}
         {success==true &&<div style={{width:'100%',maxWidth:1200,float:'center',display:'flex'}}>
       <a href='../../'> <button className='btn btn-outline-primary' style={{fontSize:14}}>Homepage</button>
  
        </a>
    
         </div>}
<br/>
      
       </div>
  )
}
