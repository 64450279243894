
import { fetchGallery} from '../Api';
import React, { useState, useEffect,useRef } from 'react';
// import GallerySlider from './GallerySlider'
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
const Gallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    const getGallery = async () => {
      setLoading(true)
      try {
        // setLoading(true)
        const data2 = await fetchGallery();
        setData(data2);
        // console.log(data2);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
    getGallery()

 
  }, []); const scrollRef = useRef(null);
  const [modal, setModal] = useState(false)
  const [images, setImages] = useState([])
  const [main, setMain] = useState('')
  const goToNextItem = () => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      scrollContainer.scrollTo({
        left: scrollContainer.scrollLeft + scrollContainer.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const goToPreviousItem = () => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      scrollContainer.scrollTo({
        left: scrollContainer.scrollLeft - scrollContainer.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div style={{display: 'flex', flexWrap: 'wrap',flexDirection:'row', justifyContent: 'space-around',minHeight:'60vh',maxWidth:1000}}>
     <Helmet>
  <title> {'Karibu Konnect | Gallery'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
     {loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'images/loader.gif'}  height={80} />
  </div>
    }
     {data.map((item,i)=>{

      return <div key={i} className='galleryCard' onClick={()=>{
        setModal(true)
        setImages(item.sections)
        setMain('https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image)
      }} style={{ position:'relative',height:300,margin:5,boxShadow: '0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)'}}>
        <div key={i} style={{width:300,height:300,padding:10,
      backgroundImage:'url('+'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image+')',
      backgroundRepeat:'no-repeat',
      backgroundPosition: 'center',
      backgroundSize:'cover'
      }}></div>
        {/* <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +item.main_image} alt="Gallery _ 1" style={{width: '100%'}} /> */}
        <div style={{position:'absolute',bottom:0,left:0,height:50,padding:5,lineHeight:'40px',background:'rgba(255,255,255,0.7)',width:'100%'}}>{item.title}</div>
      </div>
     })} 
    
 <div style={{width:300}}></div>
     <div style={{width:300}}></div>
   {modal && <div style={{ width: '100vw', zIndex: 40, height: '100vh', background: 'rgba(0,0,0,0.7)', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
        <div onClick={() => { setModal(!modal) }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
                 <button onClick={goToPreviousItem} style={{position:'fixed',userSelect:'none',bottom:10,right:100,background:'white',height:40,lineHeight:'30px',padding:5,width:80,cursor:'pointer',zIndex:32,margin:10,borderRadius:5,textAlign:'center',border:0}}>Previous</button>
                 <button onClick={goToNextItem} style={{position:'fixed',userSelect:'none',bottom:10,right:10,background:'white',padding:5,height:40,width:80,lineHeight:'30px',cursor:'pointer',zIndex:32,margin:10,borderRadius:5,textAlign:'center',border:0}}>Next</button>
      <div onClick={()=>{setModal(false)}} style={{position:'fixed',width:80,height:80,top:10,right:20,zIndex:20,textAlign:'right'}}>


<CloseIcon  style={{color:'white',fontSize:30}} />
    </div>    <div className='frost5 scrollable-container' ref={scrollRef} style={{background:'rgba(50,120,154,0.5)',width:'95%',maxWidth:1200,minWidth: 360,height:450,zIndex:5,display:'flex',alignItems:'center',flexDirection:'row',overflow:'auto',}}>
    {/* <GallerySlider images={images}/> */}
    <div  key='0'   style={{position:'relative'}}>
      <small style={{color:'white',marginLeft:10}}>{1}/{images.length+1}</small>
    <img style={{height:'auto',maxHeight:'350px',maxWidth:'98vw',padding:10,minWidth:350}} src={main} alt='' />
    
    <div style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div>
    </div>
    {images.map((img, i) => {
    return <div key={i+1}  style={{position:'relative'}}>
     <Helmet>
  <title> {'Karibu Konnect'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
      <small style={{color:'white',marginLeft:10}}>{i+2}/{images.length+1}</small>
      <img style={{height:'auto',maxHeight:'350px',maxWidth:'98vw',padding:10,minWidth:350}} src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.image} alt='' />
      
      
        
  <div style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div>
      </div> 

  // {images.map((img, i) => {
    //   return  <div key={i} style={{width:350,height:350,padding:10,
    //   backgroundImage:'url('+'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.image+')',
    //   backgroundRepeat:'no-repeat',
    //   backgroundPosition: 'center',
    //   backgroundSize:'cover'
    //   }}>
    })}
 
    </div>
      </div>}

    </div>
  );
}

export default Gallery;
