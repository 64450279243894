/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React,{useEffect} from "react";
import SwiperCore, { EffectFade,Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../App.css";

SwiperCore.use([EffectFade, Pagination,Autoplay]);


const App = (props) => {
  useEffect(()=>{

    props.setBtn_url(props.data?.sections[0]?.content_object.redirect_url)
    props.setBtn_text(props.data?.sections[0]?.content_object.button_text)
    props.getImage('https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +props.data?.sections[0]?.content_object.main_image);
},[])
  return (
    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
      <Swiper
       spaceBetween={30}
        effect={"fade"}
        grabCursor={false}
        speed={1500}
        loop= {true}  
        centeredSlides={true}
        autoplay={{
            delay: 8000,
            // disableOnInteraction: false,
          }}
        // slidesPerView={"auto"}
       fadeEffect={{
        shadow: false,               
        slideShadows: false,    
        }}
        pagination={false}
        className="mySwiper"
        onSlideChange={(swiperCore) => {
            
          const {
       
            realIndex,
          } = swiperCore;
          props.setBtn_url(props.data?.sections[realIndex]?.content_object.redirect_url)
          props.setBtn_text(props.data?.sections[realIndex]?.content_object.button_text)
          props.getImage('https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +props.data?.sections[realIndex]?.content_object.main_image);
          // console.log({ activeIndex, snapIndex, previousIndex, snapIndex });
      }}
    >

{props.data?.sections.map((img, i) => {
        return (
          <SwiperSlide key={i}> 
          {/* <div style={{height:'100%',width:'100%',background:'red'}}> */}
          <img onClick={props.showAll}  src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.content_object.main_image} alt="" />
{/* 
          <div style={{width:'100%',height:props.height,
                backgroundRepeat:'no-repeat',
                backgroundPosition: 'center',
                backgroundSize:'cover',
                backgroundImage:'url(https://cdn.sasakonnect.net/content?bucket=landing-page&file='+img.content_object.main_image+')'
        }}></div> */}
          
          </SwiperSlide>
        );
      })}
    </Swiper>
  </div>
);
};

export default App;