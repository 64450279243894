import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
// import "survey-core/defaultV2.min.css";
// import "survey-creator-core/survey-creator-core.min.css";
// import {storage,db} from './firebase-config'
// import { collection,getDocs,addDoc,query,where,getDoc,doc,updateDoc,deleteDoc   } from '@firebase/firestore'
import React,{ useState,useEffect } from "react";
import { useNavigate,useParams,Link  } from 'react-router-dom';

import { getOneSurvey,updateSurvey } from '../Api';
// import { setDatasets } from "react-chartjs-2/dist/utils";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true
};

const defaultJson = {
  pages: [{
    name: "Name",
    elements: []
  }]
};

  const SurveyCreatorWidget=()=> {
    const [schema,setSchema]=useState(defaultJson)
    const { id } = useParams();
    const [loading,setLoading] = useState(false);
 const [data,setData]=useState({});

 const [showDiv, setShowDiv] = useState(false);
    const navigate=useNavigate();
    const fetchOneSurvey = async () => {
      setLoading(true)
      try {
        getOneSurvey(id).then(data=>{
                // console.log(data)
                setData(data)
                setSchema({
                  "title": data.title,
                  "description":data.description,
                
                  "completedHtml": data.completedHtml ?? "<h3 style='line-height:300px'>Thank you for your feedback</h3>",
                  "completedHtmlOnCondition":data.completedHtmlOnCondition ?? 
                     [
                   {
                    "expression": "{nps_score} >= 9",
                    "html": "<h3>Thank you for your feedback</h3> <h4>We are glad that you love our product. Your ideas and suggestions will help us make it even better.</h4>"
                   },
                   {
                    "expression": "{nps_score} >= 6  and {nps_score} <= 8",
                    "html": "<h3>Thank you for your feedback</h3> <h4>We are glad that you shared your ideas with us. They will help us make our product better.</h4>"
                   }
                  ],
                  "pages": data.pages,
                  "showQuestionNumbers": "on"
                 })
                 setLoading(false)
        });
      
  
      //  
      } catch (error) {
        // Handle error
      }}
     
    useEffect(()=>{
     fetchOneSurvey()
    
      },[])
  //     const getSurvey = async (documentId) => {
  //        setLoading(true)
  //       const documentRef = doc(db, 'survey_schema', documentId);
      
  //       try {
  //         const documentSnapshot = await getDoc(documentRef);
      
  //         if (documentSnapshot.exists()) {
  //           const documentData = documentSnapshot.data();
           
  //           setSchema(documentData)
        
  //           creator.text=JSON.stringify(documentData)
  //           //  console.log('Document data:', documentData);
  //            setLoading(false)
  //           // Update the component state or perform any necessary actions with the document data
  //         } else {
  //           console.log('Document does not exist');
  //           setLoading(false)
  //           // Handle the case where the document does not exist
  //         }
  //       } catch (error) {
  //         setLoading(false)
  //         console.error('Error fetching document:', error);
  //         // Handle the error case
  //       }
  //     };
      

  //     const updateDocumentById = async (documentId, updatedData) => {
  //       setLoading(true)
  //       const documentRef = doc(db, 'survey_schema', documentId);
      
  //       try {
  //         await updateDoc(documentRef, updatedData);
  //         console.log('Document updated successfully');
  //         setLoading(false)
  //         // Perform any necessary actions after the document is updated
  //       } catch (error) {
  //         console.error('Error updating document:', error);
  //         setLoading(false)
  //         // Handle the error case
  //       }
  //     };


    const creator = new SurveyCreator(creatorOptions);
    creator.text =JSON.stringify(schema);
    


 

  const saveSurveySchema = async () => {
    setLoading(true)
  //   creator.saveSurveyFunc = async (saveNo, callback) => { 
     
  //     callback(saveNo, true);
  //     setSchema(creator.JSON)
  // console.log(creator.JSON)
  //     // updateDocumentById(id,creator.JSON)
  
  //   };

    
 setTimeout(()=>{

      updateSurvey(data.slug,
      
        {
          ...creator.JSON,slug:data.slug,publish:data.publish
        }
        ).then(()=>{
          // console.log(dt)
          fetchOneSurvey();
          handleButtonClick()
          setLoading(false)
        })
    }
    
    ,500)

     
    // updateDocumentById(id,schema)
    // navigate('/')
  //  console.log(schema)
  };
  // const handleSubmit2 = async () => {
  //   updateDocumentById(id,schema)
  //   navigate('/survey/admin/')
  // //  console.log(schema)
  // };
  // const deleteDocumentById = async () => {
  //   const collectionRef = collection(db, 'survey_schema');
  //   const documentRef = doc(collectionRef, id);
  
  //   try {
  //     const confirmation = window.confirm('Are you sure you want to delete this document?');
  //     if (confirmation) {
  //       await deleteDoc(documentRef);
  //       console.log('Document deleted successfully'); 
  //        navigate('/survey/admin/')
  //       // Perform any necessary actions after the document is deleted
  //     }
  //   } catch (error) {
  //     console.error('Error deleting document:', error);
  //     // Handle the error case
  //   }
  // };
  

  const handleButtonClick = () => {

    setShowDiv(true);
        setTimeout(()=>{
      setShowDiv(false);
    },2500)
    
  }

  return (
    <div >
   
      {showDiv && (   <div style={{width:'100%',justifyContent:'center',marginTop:100,display:'flex',position:'fixed',left:0,top:0,zIndex:100}}>
    <div className={`alert alert-success slide-in ${showDiv ? 'show' : ''}`} role="alert">
   Survey saved successfully
   </div></div>
      )}
      <div style={{height:50}}></div>
<nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
<Link className="navbar-brand" to="/survey/admin"> Konnect (Survey Creator) </Link>
    <div className="ml-auto">
      {!loading ?  <button 
    onClick={()=>saveSurveySchema()}
     className="btn btn-success ml-auto" type="button">Save</button> :<button 
    
      className="btn btn-success ml-auto" type="button">Saving...</button>}    
    <button
     onClick={()=>navigate('../survey/admin')} 
     className="btn btn-warning ml-2" type="button">Exit</button>
    {/* <button className="btn btn-danger ml-2" type="button"  
    // onClick={()=>deleteDocumentById()}
    
    >Delete Survey</button> */}
  </div>
 
</nav>
      <SurveyCreatorComponent creator={creator} />
         {loading &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',color:'white',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)',zIndex:1000}}>
<strong>Loading...</strong>

        </div>}
    </div>
  
  )
}
export default SurveyCreatorWidget