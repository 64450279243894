
import { fetchDeals,fetchPromotions} from '../Api';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
const Deals = () => {
  const [data, setData] = useState([]);
  const [loading,setLoading]=useState(false)
  useEffect(() => {
    const getDeals = async () => {
      try {
        setLoading(true)
        const data2 = await fetchDeals();
        setData(data2.results ?? data2);
        // console.log(data2);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
    getDeals()

 
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  
   },[])
  const [prods,setProds]=useState([])

  useEffect(()=>{

    fetchPromotions().then(resp=>{
      setProds(resp)
    })
  },[])
  return (
    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center',minHeight:'60vh'}}>
       <Helmet>
  <title> {'Karibu Konnect | Deals'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
        {loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'images/loader.gif'}  height={80} />
  </div>
    }
     {data.map((dt,i)=> <img alt='img' key= {i} className='galleryCard'  onClick={()=>{
     
              window.location.href=dt.redirect_url
            }}    style={{width:'50%',minWidth:340,padding:10,}} src={dt.main_image} />)}
          {prods.length>0 &&  <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
<div style={{width:'100%',maxWidth:1360,marginTop:20}}>
<div style={{borderBottom:'2px solid #388E3C'}}>
      <div style={{padding:5,lineHeight:'20px',color:'white',background:'#388E3C',width:200}}>Back to School Deals!</div>
      
    </div>
</div>
</div>}
         <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
<div style={{width:'100%',maxWidth:1360,marginTop:20,display:'flex',justifyContent:"center",flexWrap:'wrap'}}>
  {prods.map((item,i)=>{
    return     <a target='_blank' rel="noreferrer" href={item.redirect_url} key={i} className="card link-unstyled" style={{maxWidth:220,width:'46%',margin:5,}}>
    <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='+item.main_image} alt={'img'} className="card-img-top" style={{maxHeight:300}}/>
    <div className="card-body" style={{display:'flex',flexDirection:'column',padding:10}}>
      <h5 className="card-title" style={{fontSize:16}}>{item.product_name}</h5>
      <p className="card-text"> <font style={{color:'red',fontSize:18}}>Ksh {item.selling_price.toLocaleString()} </font><font>    <del>Ksh {item.initial_price.toLocaleString()}</del> </font></p>
   <div style={{flex:1}}></div>
    <button className='btn btn-outline-success' style={{width:'100%'}}>Add to Cart</button>
    
    
    </div>
  </a>
    
    

   
  })}

</div>
</div>
    </div>
  );
};

export default Deals;