
// import "survey-core/defaultV2.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
// import {db} from './firebase-config'
import { useState,useEffect } from "react";
import ReactHtmlParser from 'react-html-parser'; 
// import { collection,getDoc, query, where,updateDoc,doc,addDoc } from '@firebase/firestore';
import { getOneSurvey,saveSurveyResult } from '../Api';
import React from 'react'
import { useParams } from 'react-router-dom';
const defaultJson = {
  pages: [{
    name: "Name",
    elements: []
  }]
};
// const navigate=useNavigeate();
const SurveyComponent=()=> {
  
  const [schema,setSchema]=useState(defaultJson)
  const { id } = useParams();
  const [loading,setLoading] = useState(false);
const [data,setData]=useState({});
  // const navigate=useNavigate();
  const [done,setDone]=useState(false)
  const [complete,setComplete]=useState(false)
  const fetchOneSurvey = async () => {
    setLoading(true)
    try {
      getOneSurvey(id).then(data2=>{
              // console.log(data)
              setData(data2)
              setSchema({
                "title": data2.title,
                "description":data2.description,
              
                "completedHtml": data2.completedHtml ?? "<h3>Thank you for your feedback</h3>",
                "completedHtmlOnCondition":data2.completedHtmlOnCondition ?? 
                   [
                 {
                  "expression": "{nps_score} >= 9",
                  "html": "<h3>Thank you for your feedback</h3> <h4>We are glad that you love our product. Your ideas and suggestions will help us make it even better.</h4>"
                 },
                 {
                  "expression": "{nps_score} >= 6  and {nps_score} <= 8",
                  "html": "<h3>Thank you for your feedback</h3> <h4>We are glad that you shared your ideas with us. They will help us make our product better.</h4>"
                 }
                ],
                "pages": data2.pages,
                "showQuestionNumbers": "on"
               })
                 const survey_item = localStorage.getItem(data2.id);
// localStorage.setItem(data.id, false);
   
    if (survey_item) {
 setDone(true)
// 
    } 

               setLoading(false)
      });
    
      setLoading(false)
    //  
    } catch (error) {
      // Handle error
    }}


    
  useEffect(()=>{

  
  fetchOneSurvey()
    },[])

    const onSurveySubmit = (survey) => {

  saveSurveyResult({'survey' :data.id,'responses':survey.data}).then(()=>{
    setComplete(true)
    // setDone(true)
localStorage.setItem(data.id, true);
  })
};
  const survey = new Model(schema);
  return(
    <div style={{minHeight:'60vh'}}> {loading &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',color:'white',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)'}}>
<strong>Loading...</strong>

        </div>}
   
     {done==false && complete==false && <div style={{zoom:0.8}}><Survey model={survey} onComplete={onSurveySubmit} /></div>}
   {complete==false && done==true && 
<div style={{width:'100%',textAlign:'center',padding:10,paddingTop:100,paddingBottom:100,background:'#f8f8f8'}}><h2>You have already participated in this survey</h2>

<br/>
<button className='btn btn-outline-primary' onClick={()=>{
     window.location.href = '/';
}}>Go to Homepage</button>
</div>}

{(complete==true &&  done==false) && <div style={{width:'100%',textAlign:'center',padding:10,paddingTop:100,paddingBottom:100,background:'#f8f8f8'}}>{ReactHtmlParser(data.completedHtml)}

<br/>
<button className='btn btn-outline-primary' onClick={()=>{
     window.location.href = '/';
}}>Go to Homepage</button>
</div>}
</div>
  )

  
}


export default  SurveyComponent