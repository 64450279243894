/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import React, { useState } from 'react';
const ContactUsModal = ({promoUserWin,package_id,phone2,setSuccess}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [region, setRegion] = useState('');
  const [loading, setLoading] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
      if(loading==false){
        setLoading(true)
    try {
      setLoading(true)
      console.log(   {
        name,
        "region":region,
        "phone":phone2,
        "package_id":package_id,
        "foodplus_no":phone2
      })
      promoUserWin(
       {
         name,
         "region":region,
         "phone":phone2,
         "package_id":package_id,
         "foodplus_no":phone
       }).then(()=>{
    setName('')
    setPhone('')
    setRegion('')
    setLoading(false)
       handleClose();
  
     setSuccess(true)
     })
   
   
   
     
        setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
   
  

  }
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
 
    
  return (
    <>
 
<div style={{position:'fixed',width:'100%',left:0,top:100,display:'flex',flexDirection:'row',justifyContent:'center',zIndex:1000}}>



{
}
</div>
<div  onClick={handleShow} style={{padding:10,backgroundColor:'#0288D1',color:'white',borderRadius:10,fontSize:20}}> <span>Claim your prize</span></div>
         
          <div className={`modal fade scrollable-container ${show ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' ,textAlign:'left',overflow:'scroll'}}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter your details</h5>
              <button type="button" className="close" onClick={handleClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={phone}  onChange={(e)=>{setPhone(e.target.value)}}  className="form-control" id="phone" placeholder="Enter your phone number"  />
                </div>
               
              
<div className="form-group">
  <label htmlFor="selectOption" className="required">Region</label>
  <select required className="form-control" id="selectOption" value={region} onChange={handleRegionChange}>
  <option value=''>-- --</option>
    <option value='G44'>Githurai 44</option>
    <option value='G45'>Githurai 45</option>
    <option value='Zimmerman'>Zimmerman</option>
    <option value='Roysambu'>Roysambu/Mirema</option>
    <option value='Kahawa_West'>Kahawa West</option>
    <option value='Hunters_Kasarani'>Hunters/Kasarani</option>
    <option value='Lucky_Summer'>Lucky Summer</option>
    <option value='Umoja_1'>Umoja 1</option>
<option value='Umoja_2'>Umoja 2</option>
<option value='Umoja_3'>Umoja 3</option>
<option value='Pipeline'>Pipeline</option>
<option value='Kayole'>Kayole</option>
<option value='Rongai'>Rongai</option>
<option value='Kibera'>Kibera</option>
    <option value="Other">Other</option>
  </select>
</div>
            
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
             {!loading ? <button type="submit" className="btn btn-warning">Send</button>: <button  className="btn btn-warning">Sending ...</button>}
             
             
             </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show"></div>}
    </>
  );
}

export default ContactUsModal;
