/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import axios from 'axios'; 
import fernet from 'fernet'
import https from 'https';
export const BASE_URL =JSON.parse(process.env.REACT_APP_API_URL).BASE_URL;
export const TOKEN = JSON.parse(process.env.REACT_APP_API_URL).TOKEN
export const ENCKEY=JSON.parse(process.env.REACT_APP_API_URL).ENCKEY
export const fetchGallery= async () => {
  try {
  

 
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/community-gallery/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
   
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};
export const fetchTiktok= async () => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/kl-tiktokvideos/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};
export const fetchDeals= async () => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/kl-offersndeals/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};
export const fetchFaq= async () => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/konnect-faqs/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};

export const jobApplication = async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+'/api/konnect-careers-application/', obj, {
      headers: {
        Authorization: `Bearer `+TOKEN,
        'Content-Type': 'multipart/form-data'
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response;
          } 
  } catch (error) {
     if(error.response.status==404){
          window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
      }else{
         console.error(error);
      }
    
    return error;
  }
};

export const scholarshipApplication = async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+'/api/konnect-scholarship-application/', obj, {
      headers: {
        Authorization: `Bearer `+TOKEN,
        'Content-Type': 'multipart/form-data'
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response;
          } 
  } catch (error) {
     if(error.response.status==404){
          window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
      }else{
         console.error(error);
      }
    
    return error;
  }
};
export const getMainSection= async () => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/announcement-mainsection/', {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });
// console.log(response.data)
    if (response.status === 201 || response.status === 200) {
      return response.data;
      
          } 
  } catch (error) {
     if(error.response.status==404){
          window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
      }else{
         console.error(error);
      }
    
    return false;
  }
};
export const getJobsById= async (id) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/konnect-careers/'+id+'/', {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
          window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
      }else{
         console.error(error);
      }
    
    return false;
  }
};
export const getJobs= async () => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/konnect-careers/', {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
          window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
      }else{
         console.error(error);
      }
    
    return false;
  }
};
export const getResults= async (id) => {

 try {
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/survey/survey-results/'+id+'/', {
        headers: {
          Authorization: `Bearer `+TOKEN
        },
      });
  
      if (response.status === 201 || response.status === 200) {
        return response.data;
            } 
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
      
      return false;
    }
  };



  export const getResults2= async (id) => {

    try {
         process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
        
       const axiosInstance = axios.create({
         httpsAgent: new https.Agent({
           rejectUnauthorized: false // Disable SSL verification
         })
       });
   
    
       const response = await axiosInstance.get('https://mobile.sasakonnect.net/tickets/ticket-surveys/response/'+id, {
           headers: {
             Authorization: `Bearer `+TOKEN
           },
         });
     
         if (response.status === 201 || response.status === 200) {
           return response.data;
               } 
       } catch (error) {
          // if(error.response.status==404){
          //      window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
          //  }else{
              console.error(error);
          //  }
         
         return false;
       }
     };
export const saveSurveyResult = async (obj) => {
 
 try {
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+'/api/survey-results/', obj, {
        headers: {
          Authorization: `Bearer `+TOKEN
        },
      });
  
      if (response.status === 201 || response.status === 200) {
        return response.data;
            } 
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
      
      return false;
    }
  };
  export const saveSurveyResult2 = async (obj) => {
 
    try {
         process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
          process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
       const axiosInstance = axios.create({
         httpsAgent: new https.Agent({
           rejectUnauthorized: false // Disable SSL verification
         })
       });
   
    
       const response = await axiosInstance.post('https://mobile.sasakonnect.net/tickets/ticket-surveys/response', obj, {
           headers: {
             // Authorization: `Bearer `+TOKEN
             'Content-Type': 'application/json'
           },
         });
     
         if (response.status === 201 || response.status === 200) {
           return response.data;
               } 
       } catch (error) {
          if(error.response.status==404){
               window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
           }else{
              console.error(error);
           }
         
         return false;
       }
     };
export const updateSurvey = async (slug,obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.put(BASE_URL+'/api/survey-schema/'+slug+'/', obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const postSurvey = async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    // const axiosInstance = axios.create({
    //   httpsAgent: new https.Agent({
    //     rejectUnauthorized: false // Disable SSL verification
    //   })
    // });

 
    const response = await axios.post('https://mobile.sasakonnect.net/tickets/ticket-surveys', obj, {
      headers: {
        // Authorization: `Bearer `+TOKEN,
        'Content-Type': 'application/json'
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const editSurvey = async (obj,id) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    // const axiosInstance = axios.create({
    //   httpsAgent: new https.Agent({
    //     rejectUnauthorized: false // Disable SSL verification
    //   })
    // });

 
    const response = await axios.patch('https://mobile.sasakonnect.net/tickets/ticket-surveys/'+id, obj, {
      headers: {
        // Authorization: `Bearer `+TOKEN,
        'Content-Type': 'application/json'
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
    //  if(error.response.status==404){
    //         window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
    //     }else{
           console.error(error);
        // }
    
    return false;
  }
};
export const deleteSurvey = async (id) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    // const axiosInstance = axios.create({
    //   httpsAgent: new https.Agent({
    //     rejectUnauthorized: false // Disable SSL verification
    //   })
    // });

 
    const response = await axios.delete('https://mobile.sasakonnect.net/tickets/ticket-surveys/'+id);

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const createSurvey= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+'/api/survey-schema/', obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      // console.log( response.data)
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const getOneSurvey= async (slug) => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/survey-schema/'+slug+'/', {
        headers: {
          'Authorization': 'Bearer '+TOKEN
        }
      });

      if (response.status === 200 ||response.status === 201  ) {
        // console.log(response.data)
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};
export const getOneSurvey2= async (id) => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get('https://mobile.sasakonnect.net/tickets/ticket-surveys/'+id, {
        headers: {
          'Authorization': 'Bearer '+TOKEN
        }
      });

      if (response.status === 200 ||response.status === 201  ) {
        // console.log(response.data)
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
      //  if(error.response.status==404){
      //       window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
      //   }else{
           console.error(error);
        // }
    }
};
export const getSurveys= async () => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get('https://mobile.sasakonnect.net/tickets/ticket-surveys', {
        headers: {
          'Authorization': 'Bearer '+TOKEN
        }
      });

      if (response.status === 200 ||response.status === 201  ) {
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};

export const getSurveys_TICKET= async () => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get('https://mobile.sasakonnect.net/tickets/ticket-surveys', {
        headers: {
          'Authorization': 'Bearer '+TOKEN
        }
      });

      if (response.status === 200 ||response.status === 201  ) {
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};
export const fetchMainSection= async () => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/position-mainsection/', {
        headers: {
          'Authorization': 'Bearer '+TOKEN
        }
      });

      if (response.status === 200 ||response.status === 201  ) {
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};


export const postEventApplication= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+"/api/promoted-event-application/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const postEventApplication_2= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+"/api/promoted-event-application-v2/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const postEventApplication_3= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+"/api/promoted-event-application-v3/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const postComment= async (obj,id) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+"/api/trending-community-events/"+id+"/comments/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const promoUserWin= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.put(BASE_URL+"/promo/spinwhel/pupdate/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const promoWin= async (obj) => {
var secret = new fernet.Secret(ENCKEY);
var token = new fernet.Token({
  secret: secret
});

//  {obj:token.encode(JSON.stringify(obj))},
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.put(BASE_URL+"/promo/spinwhel/iupdate/", {obj:token.encode(JSON.stringify(obj))},{
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });
 
    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const fetchPromoItems= async () => {
  try {
    const decryptData = (data) => {
      var secret = new fernet.Secret(ENCKEY);
      var toke = new fernet.Token({
        secret: secret,
        token:data,ttl:0
      });

  return toke.decode()
    };
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/promo-products/', {
        headers: {
          'Authorization': 'Bearer '+TOKEN,
          'Content-Type': 'application/json'
        }
      });
    if (response.status === 200) {
      // console.log(JSON.parse(decryptData(response.data)))
          return   JSON.parse(decryptData(response.data));
        
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};
export const verifyPromo= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+"/promo/spinwhel/confirmpackage/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });

    if (response.status === 201 || response.status === 200) {
      return response.data;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    
    return false;
  }
};
export const fetchComments= async (id) => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/trending-community-events/"+id+"/comments/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};

export const fetchBanner= async () => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/kl-announcement-banner/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};
export const fetchPromotions= async () => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/kl-foodplus-promotions/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};
export const fetchAnnouncements= async () => {
  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/community-announcements/", {
      headers: {
        'Authorization': 'Bearer '+TOKEN,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
          } else {
      throw new Error('Error fetching data');
    }
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
  }
};
export const postContactUs= async (obj) => {

  try {
     process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.post(BASE_URL+"/api/contact-us/", obj, {
      headers: {
        Authorization: `Bearer `+TOKEN
      },
    });
    if (response.status === 201) {
      return true;
          } 
  } catch (error) {
     if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    return false;
  }
};
export const fetchFacebookPost = async () => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+"/api/facebook-post/", {
        headers: {
          'Authorization': 'Bearer '+TOKEN,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};
export const fetchTwitterPost = async () => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/twitter-post/', {
        headers: {
          'Authorization': 'Bearer '+TOKEN,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};
export const fetchVideo = async (category) => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/youtube-videos/?page=1&page_size=3&video_category='+category, {
        headers: {
          'Authorization': 'Bearer '+TOKEN,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        return response.data;
            } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
       if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
    }
};
export const fetchArticles = async (s) => {
  var search='';
  if(s!=null){
    search='?search='+s;
  }
 try {
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
         process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

    const response = await axiosInstance.get(BASE_URL+'/api/trending-community-events/'+search, {
          headers: {
            'Authorization': 'Bearer '+TOKEN,
            'Content-Type': 'application/json'
          }
        });
       
        if (response.status === 200) {
 
          return response.data;
              } else {
          throw new Error('Error fetching data');
        }
      } catch (error) {
         if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
      }
};
export const likeArticle = async (id,obj) => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
          process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
     const axiosInstance = axios.create({
       httpsAgent: new https.Agent({
         rejectUnauthorized: false // Disable SSL verification
       })
     });
 
  
     const response = await axiosInstance.put(BASE_URL+'/api/trending-community-events/'+id+'/',obj ,{
           headers: {
             'Authorization': 'Bearer '+TOKEN,
             'Content-Type': 'application/json'
           }
         });
         if (response.status === 200) {
           return response.data;
               } else {
           throw new Error('Error fetching data');
         }
       } catch (error) {
         
          if(error.response.status==404){
           // console.error(error);
             window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
         }else{
            console.error(error);
         }
       }
 };

 

 export const fetchOneArticles_notice = async (id) => {
  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
          process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
     const axiosInstance = axios.create({
       httpsAgent: new https.Agent({
         rejectUnauthorized: false // Disable SSL verification
       })
     });
 
  
     const response = await axiosInstance.get(BASE_URL+'/api/community-events-category/'+id+'/', {
           headers: {
             'Authorization': 'Bearer '+TOKEN,
             'Content-Type': 'application/json'
           }
         });
         if (response.status === 200) {
           return response.data;
               } else {
           throw new Error('Error fetching data');
         }
       } catch (error) {
         
          if(error.response.status==404){
           // console.error(error);
             window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
         }else{
            console.error(error);
         }
       }
 };
export const fetchOneArticles = async (id) => {
 try {
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
         process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/trending-community-events/'+id+'/', {
          headers: {
            'Authorization': 'Bearer '+TOKEN,
            'Content-Type': 'application/json'
          }
        });
        if (response.status === 200) {
          return response.data;
              } else {
          throw new Error('Error fetching data');
        }
      } catch (error) {
        
         if(error.response.status==404){
          // console.error(error);
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
      }
};
export const fetchNotices = async () => {
 try {
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
      
         process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false // Disable SSL verification
      })
    });

 
    const response = await axiosInstance.get(BASE_URL+'/api/maintenance-notice/', {
          headers: {
            'Authorization': 'Bearer '+TOKEN,
             'Content-Type': 'application/json'
          }
        });
        if (response.status === 200) {
          // console.log(response.data)
          return response.data;
              } else {
          throw new Error('Error fetching data');
        }
      } catch (error) {
         if(error.response.status==404){
            window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        }else{
           console.error(error);
        }
      }
};

export const fetchArticleNext = async (page) => {

  try {
       process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
       
          process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
     const axiosInstance = axios.create({
       httpsAgent: new https.Agent({
         rejectUnauthorized: false // Disable SSL verification
       })
     });
 
  
     const response = await axiosInstance.get(BASE_URL+'/api/trending-community-events/'+page, {
           headers: {
             'Authorization': 'Bearer '+TOKEN,
              'Content-Type': 'application/json'
           }
         });
     
         if (response.status === 200) {
           // 
           return response.data;
               } else {
           throw new Error('Error fetching data');
         }
       } catch (error) {
        //   if(error.response.status==404){
        //      window.location.pathname=window.location.pathname.split('/')[0]+'/not-found';
        //  }else{
            console.error(error);
        //  }
       }
 }

