/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import '../App.css';
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { fetchArticles,fetchArticleNext} from '../Api';
import { Link } from 'react-router-dom';
import CommunitySlider from './CommunitySlider'
import { Helmet } from 'react-helmet';

import SearchIcon from '@mui/icons-material/Search';
const Community = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [next,setNext]=useState();
  const [data2,setData2]=useState([]);
  const getData=(s)=>{
    const getArticles = async () => {
        try {
          setLoading(true)
          const data = await fetchArticles(s);
          setNext(data.next);
          setData(data.results);
    // console.log(data)
    if(s==null){
      setData2(data.results)
    }
            setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error);
        }}
        getArticles()
  }
   useEffect(() => {
    getData()
  }, []);
 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  
   },[])
//  const initialDisplayCount = 9;
  //  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  //  const loadMoreItems = () => {
  //    setDisplayCount((prevCount) => prevCount + 6);
  //  };
   const [isFocused, setIsFocused] = useState(false);

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  setIsFocused(false);
};
  return (
    <div style={{padding:5,minHeight:'60vh',maxWidth:'100vw'}}>
      {/* <h4>Community</h4> */}
      <Helmet>
  <title> {'Karibu Konnect | Articles'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
 
      {data2.filter((item,i)=>i==0).map((item,idx)=>{
  return(<div key={idx} className="row_class"  style={{width:'100%',justifyContent:'center',flexWrap:'wrap'}}>
    <div style={{flex:3,minWidth:340}}>   
     <h5 className="card-title " style={{color:'orange'}}>{item.title}</h5>
  
  <small style={{color:'gray'}}>  
  
  
  
  {new Date(item.date_created.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })} {' '}
  {item.date_created.substring(11,16)}
  {' by '} 
 </small> <small style={{color:'#0D47A1'}}>  {item.author ?? item.user.name}</small>
  {/* <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image} className="card-img-top" alt="..."/> */}
  <CommunitySlider id={item.slug}  className="card-img-top" />
  {/* <small style={{color:'gray'}}> Author: {item.user.name}</small> */}
  </div>
  <div className="card-body" style={{flex:2,minWidth:300}}>

 
    <div  style={{fontSize:14,paddingTop:40,lineHeight:'25px'}}>
       {ReactHtmlParser(item.main_section_msg)[0] }
   
    
    </div>
    <Link to={item.slug} className="btn btn-outline-primary btn-outline btn-com">Read More</Link>
  </div>
</div>)
})}
 {data2.length>0 && <div style={{borderBottom:'2px solid orange'}}>
      <div style={{padding:5,lineHeight:'20px',color:'white',marginTop:10,background:'orange',width:130}}>Other stories</div>
      
    </div>
    }
   
  {data2.length>0 &&
<div style={{display:'flex',flexDirection:'row',justifyContent:'end',width:'100%'}}>
<div style={{display:'flex',marginBottom:10,background:'white', flexDirection:'row',alignItems:'center'}}>
  <SearchIcon/>
   <input spellCheck="false"       onFocus={handleFocus}
        onBlur={handleBlur}
          onChange={(e)=>{
            
            setSearchTerm(e.target.value);
            getData(e.target.value)
            }} value={searchTerm}   style={{height:30,width:180,margin:10,background:'white',border:'1px solid #FFD54F'  ,  borderColor: isFocused ? '#FFD54F':'#FFD54F',marginLeft:0,padding:5}} placeholder='search'/>    
</div>
</div>}
{loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'images/loader.gif'}  width={80} />
  </div>
    }
      <div className='row_class' style={{justifyContent:'space-around'}}>
        {/* filter((item, i) => searchTerm !== '' || i > 0)
  .filter(item => new RegExp(searchTerm, 'i').test(item.title)).slice(0, displayCount) */}
{data.map((item,idx)=>{
  return(<div key={idx} className=" card_item"  style={{borderRadius:8,overflow:'hidden',minWidth:340,position:'relative',boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23)'}}>
   {data.length>0 && item?.main_image==null && <div className="activity"></div>}
   <Link to={item.slug}> 
   
   
   <div className="load-wraper"  style={{minHeight:230,minWidth:300,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:'cover',
        backgroundImage:data[0]?.main_image!=null ? `url(${'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item?.main_image})`: 'transparent'}}>

        </div>
   {/* <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image} className="card-img-top" alt="..." style={{minHeight:200}}/> */}
   </Link>
    <div style={{width:'100%',padding:5,display:'flex',flexDirection:'row',color:'gray',fontSize:11,alignItems:'flex-start',justifyContent:'space-between'}}>

    <span>Article by {item?.author ?? item?.user?.name}</span>
    <span> Date posted: {' '}  {new Date(item?.date_created.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</span>
  </div>
  <div style={{width:'100%',padding:10,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
<div style={{display:'flex'}}>
{/* ,left:0,bottom:0,position:'absolute' ,background:'rgba(0,0,0,0.7)'style={{borderColor:'orange',color:'orange'}}*/}

  <div style={{flex:1,minHeight:34}}>  <Link  to={item.slug} style={{textDecoration:'none',color:'white'}}>
   
   <h5 className="card-title card_title com_title"  style={{color:'black',fontSize:14,fontWeight:'nromal',lineHeight:'16px',margin:0}}> {item.title}</h5></Link>
  </div></div>
  <div style={{width:10}}></div>

</div>

  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
        <Link to={item.slug}  style={{color:'orange',fontWeight:'bold',border:0,fontSize:12,margin:5,marginRight:20,marginBottom:10}} >READ MORE</Link>
  
    </div>
  {/* <div className="card-body">
   <Link  to={"community/"+item.id} style={{textDecoration:'none'}}>
   
    <h5 className="card-title card_title">{item.title}</h5></Link>

    <div className='truncate-text'>
       {ReactHtmlParser(item.main_section_msg)[0] }
   
    
    </div>
    <small style={{color:'gray'}}>  Date posted: {' '}  {item.date_created.substring(0,10)}</small>
    <br/>
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
        <Link to={"community/"+item.slug} style={{alignSelf:'right'}} className="btn btn-outline-primary btn-outline">Read More</Link>
  
    </div>
  </div> */}
</div>)
})}

<div style={{minWidth:340,width:'32%'}}></div>
<div style={{minWidth:340,width:'32%'}}></div>
</div>
{/* {displayCount < data.filter(item => new RegExp(searchTerm, 'i').test(item.title)).length && (
  <div  style={{width:'100%',textAlign:'center',padding:20}}>
        <button onClick={loadMoreItems} style={{color:'white',background:'orange',borderRadius:30,padding:10,paddingLeft:20,paddingRight:20,fontWeight:'bold',border:0,fontSize:12,margin:5,marginRight:20,marginBottom:10}}>Load More</button>

  </div>

      )} */}
      {next!=null &&
       <div  style={{width:'100%',textAlign:'center',padding:20}}>
       <button onClick={()=>{
      fetchArticleNext(next.split('/')[next.split('/').length-1]).then((dt)=>{
     
        setNext(dt.next)
setData([...data, ...dt.results])
        })
  
       }
      
      
      } style={{color:'white',background:'orange',borderRadius:30,padding:10,paddingLeft:20,paddingRight:20,fontWeight:'bold',border:0,fontSize:12,margin:5,marginRight:20,marginBottom:10}}>Load More</button>

 </div>
      }
    </div>
  );
};

export default Community;