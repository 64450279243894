

/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import '../../App.css';
import React,{useEffect, useState} from 'react'
import { createSurvey,getSurveys_TICKET } from '../../Api';
import { Link,  } from 'react-router-dom';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { postSurvey,editSurvey,deleteSurvey } from '../../Api';
export  const KonnectSurvey=()=> {

    const [error,setError] = useState("");
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    
    const [showModal, setShowModal] = useState(false);
    const [list,setList]=useState([])
    const [loading,setLoading] = useState(false);
    const openModal = () => {
      setShowModal(true);
    };
    
    const closeModal = () => {
      setShowModal(false);
    };
      const fetchSurvey = async () => {
        try {
          const data = await getSurveys_TICKET();
          // console.log(data)
          setList(data)
        } catch (error) {
          // Handle error
        }}
    useEffect(()=>{
    
        fetchSurvey()
    },[])
    
    
    
    // const getSurveys=async ()=>{
    //   setLoading(true)
    // const g=query(collection(db, "survey_schema") );
    
    //   const data =await  getDocs(g);
    //   var result=data.docs.map((doc)=>({...doc.data(),id:doc.id}));
    //    setList(result)
    //   setLoading(false)
    // }
    
    function slugGenerator(inputString) {

      const transformedString = inputString.replace(/\s+/g, '-');
      
      const randomNumber = Math.floor(Math.random() * 90000) + 10000;
      
  
      const finalString = transformedString + "-" +randomNumber;
      
      return finalString;
    }
    const createNewDocument = async () => {
    
      if(title.trim()!='' || description.trim()!=''){
        
      setError('')
      closeModal()
      // const collectionRef = collection(db, 'survey_schema');
    var data={
      "title":title,
      "description":description,
      "slug":slugGenerator(title),
      "pages":[]
    }
      try {
        setLoading(true)
        // const newDocRef = await addDoc(collectionRef, data);
        // console.log('New document created with ID:', newDocRef.id);
        // setDescription('')
        // setTitle('')
        createSurvey(data).then(()=>{
//  console.log(response)
 setLoading(false)
 fetchSurvey()
        })
        // getSurveys()
        // setLoading(false)
       
        
        // Perform any necessary actions after the document is created
      } catch (error) {
        setLoading(false)
        console.error('Error creating new document:', error);
        // Handle the error case
      }}else{
        setError('Please fill in the title and description')
      }
    };
    
 const [json2,setJson]=useState('')
//  const [valid,setValid]=useState(null)
 const isValidJson=(jsonString)=>{
  try {
      JSON.parse(jsonString);
      if(typeof JSON.parse(jsonString) == 'object'){
          return true;
      }else{  return false;}
    
  } catch (error) {
      return false;
  }
};

const onSubmit=()=>{
  try { if(typeof JSON.parse(json2) != 'object'){
       alert('Please enter a valid json object');
  }else{
    if (  typeof JSON.parse(json2) === 'object' &&  // Check if it's an object
            // Exclude null
  typeof JSON.parse(json2)['title']!=undefined && 
  typeof JSON.parse(json2)['description']!=undefined &&            // Check for "title" key
  typeof JSON.parse(json2)['completedHtml']!=undefined && 
  typeof JSON.parse(json2)['completedHtmlOnCondition']!=undefined 
 
    ) {
      saveSurveySchema();

  } else {
      console.log("Object is missing some fields");
  }
}  } catch (error) {
  alert('Please enter a valid json object');
}
  
  }

  const [msg,setMsg]=useState('');
  const [success,setSuccess]=useState(false)
  const saveSurveySchema = async () => {
    setLoading(true)
   
    setSuccess(false)
if(editMode==true){
  var t=JSON.parse(json2);
  delete t._id;
  delete t.createdAt;
  delete t.updatedAt;
  delete t.__v;
  console.log(t)
  editSurvey(      
    t,list[selected]._id
      ).then((dt)=>{
        
        fetchSurvey();
        if(dt==false){
          alert("Object is missing some fields");
        }else{
          setSuccess(true)
          setJson('')
          setEditMode(false)
setMsg('Survey Updated successfully');
        }
        
        console.log(dt)

        setLoading(false)
      })
}else{
postSurvey(      
      json2
        ).then((dt)=>{
          fetchSurvey();
          if(dt==false){
            alert("Object is missing some fields");
          }else{
            setSuccess(true)
            setJson('')
            setMsg('Survey Created successfully');
          }
          
          // console.log(dt)
          setLoading(false)
        })
}
    
    }
    const deleteSchema = async (id) => {
      setLoading(true)
     
  
  
      deleteSurvey(      
        id
          ).then((dt)=>{
            fetchSurvey();
            console.log(dt)
            setLoading(false)
          })
      }
  
const [newSurvey,setNewSurvey]=useState(false)
const [editMode,setEditMode]=useState(false)
const [selected,setSelected]=useState(false)
      return (
        <div>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <Link className="navbar-brand" to="/survey/admin"> Konnect (Survey Creator) </Link>
      
     
    </nav>
    <div style={{display:'flex'}}>
 <div style={{flex:1,padding:20,height:'90vh',overflow:'auto',}}>
    
            <div style={{width:'100%',maxWidth:1000,display:'flex',flexWrap:'wrap',flexDirection:'column',justifyContent:'space-between'}}>
            <div className='surveycard2' style={{width:'100%',paddingTop:20,}} onClick={openModal}>
              </div> 
           {!newSurvey &&  <div><button className="btn btn-warning ml-2" onClick={()=>{
                 setSuccess(false)
                setNewSurvey(!newSurvey);
                setJson('')
                
              }} >+Create New Survey</button></div>}
            <br/>
            <h5>Survey List</h5>
                {list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item,i)=><div  key={i}  className='surveycard'>
                  <div style={{margin:10}}>
                  <div className="card-body">
    <h5 className="card-title"> {item.title}  </h5>
    {/* <h6 className=" mb-2 ">Card subtitle</h6> */}
    <p className="card-text card-subtitle text-muted" >  {item.description}</p>
    <button className='btn btn-danger' style={{margin:5}} onClick={()=>{
      const confirmation = confirm("Click Ok to confirm deletion");
      if (confirmation) {
         deleteSchema(item._id)
      }
     
      
      }}>Delete</button>
       <button className='btn btn-outline-primary' style={{margin:5}} onClick={()=>{

   setNewSurvey(true)
         setJson(JSON.stringify(item, null, 2));
         setEditMode(true)
         setSuccess(false)
         setSelected(i)
      }}>Edit Survey</button>
    {/* <Link to={'/ticket/survey/admin/analytics/'+item.id+'/'+item.slug}   className="card-link">Analytics</Link> */}
    <Link to={'/ticket/survey/view/'+item._id}   className="card-link">  
     <button className='btn btn-primary' style={{margin:5}} >View</button></Link>
  </div>
                            
                  
                   {/* <br/>
                <div><small style={{color:'gray'}}>{item.description}</small></div> */}
                
                
                </div>
    
                </div>)}
            </div></div>

            {/* ////////////////////////////////////// */}
            {newSurvey==true &&       <div style={{flex:1,minHeight:'90vh',height:'100%',display:'flex',justifyContent:'center',backgroundColor:'#f8f8f8'}}>
            <div>
              <br/>
              <div style={{display:"flex",justifyContent:'space-between'}}>
<h5>Survey Editor  {editMode==true && ' - '+list[selected].title+' (UPDATE)'}</h5>

  <button className='btn btn-outline-success' onClick={()=>{
       window.open("https://surveyjs.io/survey-creator/examples/free-nps-survey-template/reactjs", "_blank");

  }} style={{marginBottom:10}}>Draft a survey</button>

                </div>
              
                <AceEditor
 mode="javascript" 
 theme="monokai"
 
    onChange={(d)=>{
      // console.log(JSON.parse(d));
       setJson(d);
     
    }}
    value={json2}
   
  name="json-editor"
    editorProps={{ $blockScrolling: true }}
    setOptions={{
        tabSize: 2,
    }}
    style={{width:'45vw'}}
  />
      <strong style={{color:'green'}}>{isValidJson(json2)==true && json2!='' &&  'This is a valid json object'}</strong>
  <br/>
  <strong style={{color:'green'}}>{success &&  msg}</strong>
    <strong style={{color:'red'}}>
       {isValidJson(json2)==false && json2!='' && 'Not a valid json object'}
    </strong>
<div>
    {newSurvey && <button className="btn btn-outline-danger ml-2" onClick={()=>{
                setNewSurvey(!newSurvey);
                setEditMode(false)
              }} style={{marginTop:10}}  >Close</button>}
              {
                editMode==true?
                <button onClick={()=>{onSubmit()}} style={{marginTop:10,float:'right'}} className='btn btn-primary'>Update</button>  :
  <button onClick={()=>{onSubmit()}} style={{marginTop:10,float:'right'}} className='btn btn-primary'>Submit</button>
              }
    </div>
  </div>
 
             </div>}
    </div>
   
    
            {loading &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',color:'white',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)'}}>
    <strong>Loading...</strong>
    
            </div>}
    
        
            {showModal &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)'}}>
            <div   onClick={closeModal} style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',top:0,left:0}}>
     </div>
      <div className="modal-dialog" role="document">
                 <div className="modal-content" style={{minWidth:'400px'}}>
                   <div className="modal-header">
                     <h5 className="modal-title">Create new survey</h5>
                     {/* <button
                       type="button"
                       className="btn-close"
                       onClick={closeModal}
                       aria-label="Close"
                     >Close</button> */}
                   </div>
                   <div className="modal-body">
                     <div className="mb-3">
                       <label htmlFor="title" className="form-label">Title</label>
                       <input
                         type="text"
                         className="form-control"
                         id="title"
                         value={title}
                         onChange={(e) => setTitle(e.target.value)}
                       />
                     </div>
                     <div className="mb-3">
                       <label htmlFor="description" className="form-label">Description</label>
                       <textarea
                         className="form-control"
                         id="description"
                         rows="3"
                         value={description}
                         onChange={(e) => setDescription(e.target.value)}
                       ></textarea>
                     </div>
                     <small style={{color:'red'}}>  {error}</small>
                   </div>
                   
                 
                      <div className="modal-footer">
                      <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={()=>{createNewDocument()}}
                        >
                        Create Survey
                        </button>
                      </div>
                    </div>
                  </div>
            </div>}
        
        </div>
      
      )
    }
    export default KonnectSurvey;