/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import '../App.css'
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { getMainSection,fetchNotices} from '../Api';
import  Slider1 from './slider1';
import  Slider2 from './slider2';
import  Slider3 from './slider3';
import  Slider4 from './slider4';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
function isYouTubeLink(url) {
  try {

    const shortLinkPrefix = "https://youtu.be/";
  
  if (url.startsWith(shortLinkPrefix)) {
    // Extract the video ID from the short link
    const videoId = url.split('/').pop();
    
    // Construct the full YouTube URL
     url = `https://www.youtube.com/watch?v=${videoId}`;
      }

    const youtubeUrl = new URL(url);
    const hostname = youtubeUrl.hostname;
    const pathname = youtubeUrl.pathname;

    return (
      (hostname === 'www.youtube.com' || hostname === 'youtube.com') &&
      pathname === '/watch' &&
      youtubeUrl.searchParams.has('v')
    ) || (
      hostname === 'youtu.be' &&
      youtubeUrl.pathname !== '/' &&
      youtubeUrl.pathname.split('/').length === 2
    );
  } catch (error) {
    // console.error('Invalid YouTube URL');
    return false;
  }
}
function getYouTubeVideoId(url) {
  try {
    const shortLinkPrefix = "https://youtu.be/";
  
    if (url.startsWith(shortLinkPrefix)) {
      // Extract the video ID from the short link
      const videoId = url.split('/').pop();
      
      // Construct the full YouTube URL
       url = `https://www.youtube.com/watch?v=${videoId}`;
        }
    const youtubeUrl = new URL(url);
    const searchParams = new URLSearchParams(youtubeUrl.search);
    const videoId = searchParams.get('v');
    
    if (videoId) {
      return videoId;
    }
  } catch (error) {
    console.error('Invalid YouTube URL');
  }
  
  return null;
}
const Topnews = () => {
  // const navigate=useNavigate()
  // const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  // const [url, setUrl] = useState('')
  // const [modal, setModal] = useState(false)
  const [parentWidth, setParentWidth] = useState(0);
  const [parentWidth2, setParentWidth2] = useState(0);
  const [notices,setNotices]=useState([])
    const parentRef = useRef(null);
    const parentRef2 = useRef(null);
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
      if (parentRef2.current) {
  
        setParentWidth2(parentRef2.current.offsetWidth);
      }
    //   console.log(parentRef.current.offsetWidth)
    };

    
  
    
  useEffect(() => {
    const getNotices = async () => {
      try {
        const data = await fetchNotices();
        setNotices(data);
        // console.log(data)
      } catch (error) {
        // Handle error
      }}
     
      getNotices()
       const fetchMainSection = async () => {
        try {
          // setLoading(true)
          const data = await getMainSection();
          setData2(data);
      
        } catch (error) {
          // setLoading(false)
          console.log(error);
        }}
        fetchMainSection()

        handleResize(); // Initial width
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
  }, []);

 
  return (
    <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%',overflow:'hidden',
    }}>
      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
         {notices?.map((notice,idx)=><div key={idx}  style={{width:'100%',maxWidth:1360,display:'flex'}}>
    
       
    <div className={notice.title_txt_color=="black"?"alert alert-warning":notice.title_txt_color=="red"?"alert alert-warning":"alert alert-success"} role="alert"  style={{display:'flex',alignItems:'center',width:'100%'}}>
       {/* <div style={{margin:5}}>
         <i class="fa fa-bullhorn" aria-hidden="true" style={{fontSize:40}}></i>  
   </div> */}
   <div>
    
   <div style={{color:notice.title_txt_color??"red"}}>{notice.title}</div>
  
    {notice?.subtitle}
{notice?.more_detail && <a href={notice?.more_detail_url} style={{marginLeft:20}}><button className='btn  btn-outline-primary'>{notice?.more_detail_btn_txt}</button></a>}
  </div>
</div> </div>)}
      </div>
         
         
  {/* <img  src='https://cdn.sasakonnect.net/content?bucket=landing-page&file=3eb4b300-bbd2-4b34-a4d3-6439406fb47f' width='400px'  /> */}
    <div ref={parentRef} style={{background:'#f8f8f8',maxWidth:1366,width:'100%',display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',justifyContent:'center'}}>
 <div ref={parentRef2} style={{position:'relative',flex:1,overflow:'hidden',minWidth:parentWidth*0.50}}>
<BlareSlider height={parentWidth*0.5} data={data2[0]??[]} width={'100%'} minWidth={360}  minHeight={300}/>


  </div>
  <div style={{width:3}} className='divider'  ></div>
 <div style={{background:'white',flex:1,height:parentWidth2,minWidth:360,minHeight:400,overflow:'hidden'}}>
 <div style={{height:3}} className='divider2'></div>
{/* //////////////////////////////////// */}
 <div style={{display:'flex',flexDirection:'row',minWidth:parentWidth*0.50}}>

 <div style={{position:'relative',flex:1,overflow:'hidden'}}>
   <BlareSlider2 data={data2[1]??[]} height={parentWidth2*0.5}  width={'100%'}   minWidth={180}  minHeight={180}/>
 
   </div>
<div style={{width:3}}></div>
<div style={{position:'relative',flex:1,overflow:'hidden'}}>
   <BlareSlider3 data={data2[2]??[]}  height={parentWidth2*0.5}  width={'100%'}   minWidth={180}  minHeight={180}/>
   
  
</div>
  </div>
  {/* ////////////////////////////// */}
  <div style={{height:3}}></div>
 <div style={{position:'relative',flex:1,overflow:'hidden'}}>
 <BlareSlider4 data={data2[3]??[]}   height={parentWidth2*0.5} width={'100%'}  minWidth={360}  minHeight={180}/>

</div>
</div>
    </div>
    </div>
  );
};
const BlareSlider=(props)=>{
  const [image1, setImage1] = useState('');  const [video,setVideo]=useState('')
  const [modal,setModal]=useState(false)
    const [btn_txt, setBtn_text] = useState('')
    const [btn_url, setBtn_url] = useState('')
    const [isZoomed, setIsZoomed] = useState(false);
     const [display,setDisplay]=useState(false)
 
    const navigate=useNavigate()
  useEffect(()=>{
   

       handleZoomClick(display)
    
   
  },[display])
    const handleZoomClick = (v) => {
  
      setIsZoomed(v);
      // if(isZoomed==false){
      //   setDisplay(false)
      // }
    };
    const swipeRef = useRef(null);
    let startX = 0;
  
    const handleTouchStart = (event) => {
      startX = event.touches[0].clientX;
    };
  
    const handleTouchEnd = (event) => {
      const endX = event.changedTouches[0].clientX;
      const deltaX = endX - startX;
  
      // Set the minimum swipe distance required to trigger the swipe right event
      const minSwipeDistance = 100;
  
      if (deltaX > minSwipeDistance) {
        // Swipe right logic here
        if(display==true){
          setIsZoomed(false)
          setTimeout(()=>{
            setDisplay(!display)
          },550)
                       }else{
                         setDisplay(!display)
                       }
      }
    };
    const handleTouchMove = (event) => {
      event.preventDefault(); // Prevent scrolling while swiping
    };
  
    return   <div style={{width:props.width,height:props.height,minWidth:props.minWidth,minHeight:props.minHeight,
    backgroundImage:'url('+image1+')',
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
    backgroundSize:'cover',position:'relative',
    display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:'#e5e5e5'
  }}   >

        {(btn_txt=='' || btn_txt==null)  && <div className="activity" style={{zIndex:15}}></div>}
   {display && <div className='frost6'   onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100vh',width:'100vw',top:0,left:0,position:'fixed',zIndex:15}}></div>}
   {display && <div           ref={swipeRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove} className={`zoom-center-container ${isZoomed ? 'zoomed' : ''}`} >
    {/* <button className='btn' onClick={()=>{setDisplay(!display)}} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
    <div    onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100vh',top:0,left:0,position:'absolute',zIndex:-1}}></div>
            <div  onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }}  style={{width:'100%',textAlign:'right',height:50}}>
            <div >
          <CloseIcon style={{ color: 'white' }} />
          </div>
              </div>
    {props.data?.sections?.map((img, i) => {
      
          return (
         <div  key={i}  className='galleryCard'   style={{width:'50%',maxWidth:600,minWidth:340,position:'relative'}} >
      

           <img style={{width:'100%',border:'1px solid white',padding:0,maxWidth:600,minWidth:340}} src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.content_object.main_image} alt="" />
  <div onClick={()=>{
                 var s=isYouTubeLink(img.content_object.redirect_url)
               
               if(s==true)
               {
                 setModal(true);
                 setVideo(img.content_object.redirect_url)
               }else{
                const baseURL = window.location.origin;
                const indexOfBaseURL = img.content_object.redirect_url.indexOf(baseURL);
                   if (indexOfBaseURL > -1) {
                   
                       navigate(img.content_object.redirect_url.slice(indexOfBaseURL + baseURL.length))
                   } else {
                     window.location.href =img.content_object.redirect_url
               
                   }
               }
  
  
            }}  style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div>

  <div style={{padding:5,marginBottom:10,color:'white',background:'#00796B',width:'100%',textAlign:'left'}}>
  {img.content_object.button_text}
    
  </div>
          </div>
           
          );
        })}
      
    </div>}
  <div style={{  position: 'absolute',  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
    top: 0,
    left: 0,
    width: '100%',
    height:' 100%',
  
    backdropFilter: 'blur(80px)'}}></div>{props.data?.sections?.length>0 && <Slider1 height={props.height}  showAll={()=>{
                if(display==true){
                 setIsZoomed(false)
                 setTimeout(()=>{
                   setDisplay(!display)
                 },550)
                              }else{
                                setDisplay(!display)
                              }
                            
 
           }}  setBtn_url={setBtn_url}  setBtn_text={setBtn_text} getImage={(value)=>{setImage1(value)}} data={props.data}/>  }
  
  {/* <div  style={{  position: 'absolute',zIndex:5,
  bottom: 0,
  left: 0,
  width: '100%',
  height:' 100%'}}></div> */}
  
 {btn_txt!='' &&<div style={{width:'100%',background:'rgba(38, 194, 129,0.6)',zIndex:10,position:'absolute',bottom:0,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
            <button className='btn'  onClick={()=>{
                 var s=isYouTubeLink(btn_url)
                 console.log(btn_url)
               if(s==true)
               {
                 setModal(true);
                 setVideo(btn_url)
               }else{
                const baseURL = window.location.origin;
                const indexOfBaseURL = btn_url.indexOf(baseURL);
                   if (indexOfBaseURL > -1) {
                   
                       navigate(btn_url.slice(indexOfBaseURL + baseURL.length))
                   } else {
                     window.location.href =btn_url
               
                   }
              
               }
  
  
            }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{btn_txt}</button>
             {/* <button className='btn' onClick={()=>{
               if(display==true){
  setIsZoomed(false)
  setTimeout(()=>{
    setDisplay(!display)
  },1000)
               }else{
                 setDisplay(!display)
               }
             
              
              }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
          </div>}
        {modal && <div style={{ width: '100vw', zIndex: 40, height: '100vh', background: 'rgba(0,0,0,0.8)', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
        <div onClick={() => { setModal(!modal) }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
        <div style={{background:'black',width:'95%',maxWidth:800,minWidth: 360,height:'50vw',maxHeight:400,zIndex:51}}>
      <iframe
            style={{ minHeight: 230, width: '100%', minWidth: 300, height: '100%' }}
                  src={`https://www.youtube.com/embed/`+getYouTubeVideoId(video)+'?rel=0'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>}
</div>
 }
 const BlareSlider2=(props)=>{
  const [image1, setImage1] = useState('');  const [video,setVideo]=useState('')
  const [modal,setModal]=useState(false)
    const [btn_txt, setBtn_text] = useState('')
    const [btn_url, setBtn_url] = useState('')
    const [isZoomed, setIsZoomed] = useState(false);
     const [display,setDisplay]=useState(false)
    const navigate=useNavigate()
  useEffect(()=>{
  
       handleZoomClick(display)
    
   
  },[display])
    const handleZoomClick = (v) => {
  
      setIsZoomed(v);
      // if(isZoomed==false){
      //   setDisplay(false)
      // }
    };
    const swipeRef = useRef(null);
    let startX = 0;
  
    const handleTouchStart = (event) => {
      startX = event.touches[0].clientX;
    };
  
    const handleTouchEnd = (event) => {
      const endX = event.changedTouches[0].clientX;
      const deltaX = endX - startX;
  
      // Set the minimum swipe distance required to trigger the swipe right event
      const minSwipeDistance = 100;
  
      if (deltaX > minSwipeDistance) {
        // Swipe right logic here
        if(display==true){
          setIsZoomed(false)
          setTimeout(()=>{
            setDisplay(!display)
          },550)
                       }else{
                         setDisplay(!display)
                       }
      }
    };
    const handleTouchMove = (event) => {
      event.preventDefault(); // Prevent scrolling while swiping
    };
  
    return   <div style={{width:props.width,height:props.height,minWidth:props.minWidth,minHeight:props.minHeight,
    backgroundImage:'url('+image1+')',
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
    backgroundSize:'cover',position:'relative',
    display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:'#e5e5e5'
  }}   >

        {(btn_txt=='' || btn_txt==null)  && <div className="activity" style={{zIndex:15}}></div>}
   {display && <div className='frost6'   onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100vh',width:'100vw',top:0,left:0,position:'fixed',zIndex:15}}></div>}
   {display && <div           ref={swipeRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove} className={`zoom-center-container ${isZoomed ? 'zoomed' : ''}`} >
    {/* <button className='btn' onClick={()=>{setDisplay(!display)}} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
    <div    onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100vh',top:0,left:0,position:'absolute',zIndex:-1}}></div>
            <div  onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }}  style={{width:'100%',textAlign:'right',height:50}}>
            <div >
          <CloseIcon style={{ color: 'white' }} />
          </div>
              </div>
    {props.data?.sections?.map((img, i) => {
      
          return (
         <div  key={i}  className='galleryCard'   style={{width:'50%', height: 'auto',maxWidth:600,minWidth:340,position:'relative'}} >
         
         {/* {true && <div className='frost6'   onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100%',width:'100%',top:0,left:0,position:'absolute',zIndex:-1}}></div>} */}
  
           <img style={{width:'100%',border:'1px solid white',padding:0,maxWidth:600,minWidth:340}} src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.content_object.main_image} alt="" />
    <div onClick={()=>{
                 var s=isYouTubeLink(img.content_object.redirect_url)
               
               if(s==true)
               {
                 setModal(true);
                 setVideo(img.content_object.redirect_url)
               }else{
          
                 const baseURL = window.location.origin;
                 const indexOfBaseURL = img.content_object.redirect_url.indexOf(baseURL);
                    if (indexOfBaseURL > -1) {
                    
                        navigate(img.content_object.redirect_url.slice(indexOfBaseURL + baseURL.length))
                    } else {
                      window.location.href =img.content_object.redirect_url
                
                    }
               
               }
  
  
            }}  style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div>
  <div style={{padding:5,marginBottom:10,color:'white',background:'#F57C00',width:'100%',textAlign:'left'}}>
  {img.content_object.button_text}
    
  </div>
          </div>
           
          );
        })}
      
    </div>}
  <div style={{  position: 'absolute',  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
    top: 0,
    left: 0,
    width: '100%',
    height:' 100%',
  
    backdropFilter: 'blur(80px)'}}></div>{props.data?.sections?.length>0 && <Slider2 height={props.height}  showAll={()=>{
                if(display==true){
                 setIsZoomed(false)
                 setTimeout(()=>{
                   setDisplay(!display)
                 },550)
                              }else{
                                setDisplay(!display)
                              }
                            
 
           }} setBtn_url={setBtn_url}  setBtn_text={setBtn_text} getImage={(value)=>{setImage1(value)}} data={props.data}/>  }
  
  {/* <div   style={{  position: 'absolute',zIndex:5,
  bottom: 0,
  left: 0,
  width: '100%',
  height:' 100%'}}></div> */}
  
  {btn_txt!='' && <div style={{width:'100%',background:'rgba(243, 156, 18,0.6)',zIndex:10,position:'absolute',bottom:0,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
            <button className='btn'  onClick={()=>{
                 var s=isYouTubeLink(btn_url)
                 console.log(btn_url)
               if(s==true)
               {
                 setModal(true);
                 setVideo(btn_url)
               }else{
                const baseURL = window.location.origin;
                const indexOfBaseURL = btn_url.indexOf(baseURL);
                   if (indexOfBaseURL > -1) {
                   
                       navigate(btn_url.slice(indexOfBaseURL + baseURL.length))
                   } else {
                     window.location.href =btn_url
               
                   }
              
               }
  
  
            }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{btn_txt}</button>
             {/* <button className='btn' onClick={()=>{
               if(display==true){
  setIsZoomed(false)
  setTimeout(()=>{
    setDisplay(!display)
  },1000)
               }else{
                 setDisplay(!display)
               }
             
              
              }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
          </div>}
        {modal && <div style={{ width: '100vw', zIndex: 40, height: '100vh', background: 'rgba(0,0,0,0.8)', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
        <div onClick={() => { setModal(!modal) }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
        <div style={{background:'black',width:'95%',maxWidth:800,minWidth: 360,height:'50vw',maxHeight:400,zIndex:51}}>
      <iframe
            style={{ minHeight: 230, width: '100%', minWidth: 300, height: '100%' }}
                  src={`https://www.youtube.com/embed/`+getYouTubeVideoId(video)+'?rel=0'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>}
</div>
 }
 const BlareSlider3=(props)=>{
  const [image1, setImage1] = useState('');  const [video,setVideo]=useState('')
  const [modal,setModal]=useState(false)
    const [btn_txt, setBtn_text] = useState('')
    const [btn_url, setBtn_url] = useState('')
    const [isZoomed, setIsZoomed] = useState(false);
     const [display,setDisplay]=useState(false)
    const navigate=useNavigate()
 
  useEffect(()=>{
  
       handleZoomClick(display)
    
   
  },[display])
    const handleZoomClick = (v) => {
  
      setIsZoomed(v);
      // if(isZoomed==false){
      //   setDisplay(false)
      // }
    };
    const swipeRef = useRef(null);
    let startX = 0;
  
    const handleTouchStart = (event) => {
      startX = event.touches[0].clientX;
    };
  
    const handleTouchEnd = (event) => {
      const endX = event.changedTouches[0].clientX;
      const deltaX = endX - startX;
  
      // Set the minimum swipe distance required to trigger the swipe right event
      const minSwipeDistance = 100;
  
      if (deltaX > minSwipeDistance) {
        // Swipe right logic here
        if(display==true){
          setIsZoomed(false)
          setTimeout(()=>{
            setDisplay(!display)
          },550)
                       }else{
                         setDisplay(!display)
                       }
      }
    };
    const handleTouchMove = (event) => {
      event.preventDefault(); // Prevent scrolling while swiping
    };
  
    return   <div style={{width:props.width,height:props.height,minWidth:props.minWidth,minHeight:props.minHeight,
    backgroundImage:'url('+image1+')',
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
    backgroundSize:'cover',position:'relative',
    display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:'#e5e5e5'
  }}   >

        {(btn_txt=='' || btn_txt==null)  && <div className="activity" style={{zIndex:15}}></div>}
   {display && <div className='frost6'   onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100vh',width:'100vw',top:0,left:0,position:'fixed',zIndex:15}}></div>}
   {display && <div           ref={swipeRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove} className={`zoom-center-container ${isZoomed ? 'zoomed' : ''}`} >
    {/* <button className='btn' onClick={()=>{setDisplay(!display)}} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
    <div    onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }} style={{height:'100vh',top:0,left:0,position:'absolute',zIndex:-1}}></div>
            <div  onClick={()=>{
                 if(display==true){
                  setIsZoomed(false)
                  setTimeout(()=>{
                    setDisplay(!display)
                  },550)
                               }else{
                                 setDisplay(!display)
                               }
                             
  
            }}  style={{width:'100%',textAlign:'right',height:50}}>
            <div >
          <CloseIcon style={{ color: 'white' }} />
          </div>
              </div>
    {props.data?.sections?.map((img, i) => {
      
          return (
         <div  key={i}  className='galleryCard'   style={{width:'50%',maxWidth:600,minWidth:340,position:'relative'}} >
           <img style={{width:'100%',border:'1px solid white',padding:0,maxWidth:600,minWidth:340}} src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.content_object.main_image} alt="" />
    <div onClick={()=>{
                 var s=isYouTubeLink(img.content_object.redirect_url)
               
               if(s==true)
               {
                 setModal(true);
                 setVideo(img.content_object.redirect_url)
               }else{
                const baseURL = window.location.origin;
                const indexOfBaseURL = img.content_object.redirect_url.indexOf(baseURL);
                   if (indexOfBaseURL > -1) {
                   
                       navigate(img.content_object.redirect_url.slice(indexOfBaseURL + baseURL.length))
                   } else {
                     window.location.href =img.content_object.redirect_url
               
                   }
               }
  
  
            }} style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div>
  <div style={{padding:5,marginBottom:10,color:'white',background:'#0288D1',width:'100%',textAlign:'left'}}>
  {img.content_object.button_text}
    
  </div>
          </div>
           
          );
        })}
      
    </div>}
  <div style={{  position: 'absolute',  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
    top: 0,
    left: 0,
    width: '100%',
    height:' 100%',
  
    backdropFilter: 'blur(80px)'}}>
      
      
      </div>{props.data?.sections?.length>0 && <Slider3 height={props.height}  showAll={()=>{
                if(display==true){
                 setIsZoomed(false)
                 setTimeout(()=>{
                   setDisplay(!display)
                 },550)
                              }else{
                                setDisplay(!display)
                              }
                            
 
           }}  setBtn_url={setBtn_url}  setBtn_text={setBtn_text} getImage={(value)=>{setImage1(value)}} data={props.data}/>  }
  {/* <div  style={{  position: 'absolute',zIndex:5,
  bottom: 0,
  left: 0,
  width: '100%',
  height:' 100%'}}></div> */}
  
  
  {btn_txt!='' && <div style={{width:'100%',background:'rgba(3, 138, 255,0.6)',zIndex:10,position:'absolute',bottom:0,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
            <button className='btn'  onClick={()=>{
                 var s=isYouTubeLink(btn_url)
                 console.log(btn_url)
               if(s==true)
               {
                 setModal(true);
                 setVideo(btn_url)
               }else{
                 const baseURL = window.location.origin;
                const indexOfBaseURL = btn_url.indexOf(baseURL);
                   if (indexOfBaseURL > -1) {
                   
                       navigate(btn_url.slice(indexOfBaseURL + baseURL.length))
                   } else {
                     window.location.href =btn_url
               
                   }
              
               }
  
  
            }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{btn_txt}</button>
             {/* <button className='btn' onClick={()=>{
               if(display==true){
  setIsZoomed(false)
  setTimeout(()=>{
    setDisplay(!display)
  },1000)
               }else{
                 setDisplay(!display)
               }
             
              
              }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
          </div>}
        {modal && <div style={{ width: '100vw', zIndex: 40, height: '100vh', background: 'rgba(0,0,0,0.8)', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
        <div onClick={() => { setModal(!modal) }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
        <div style={{background:'black',width:'95%',maxWidth:800,minWidth: 360,height:'50vw',maxHeight:400,zIndex:51}}>
      <iframe
            style={{ minHeight: 230, width: '100%', minWidth: 300, height: '100%' }}
                  src={`https://www.youtube.com/embed/`+getYouTubeVideoId(video)+'?rel=0'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>}
</div>
 }
 const BlareSlider4=(props)=>{
  const [image1, setImage1] = useState('');  const [video,setVideo]=useState('')
const [modal,setModal]=useState(false)
  const [btn_txt, setBtn_text] = useState('')
  const [btn_url, setBtn_url] = useState('')
  const [isZoomed, setIsZoomed] = useState(false);
   const [display,setDisplay]=useState(false)
    const navigate=useNavigate()
useEffect(()=>{

     handleZoomClick(display)
  
 
},[display])
  const handleZoomClick = (v) => {

    setIsZoomed(v);
    // if(isZoomed==false){
    //   setDisplay(false)
    // }
  };
  const swipeRef = useRef(null);
  let startX = 0;

  const handleTouchStart = (event) => {
    startX = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    const endX = event.changedTouches[0].clientX;
    const deltaX = endX - startX;

    // Set the minimum swipe distance required to trigger the swipe right event
    const minSwipeDistance = 100;

    if (deltaX > minSwipeDistance) {
      // Swipe right logic here
      if(display==true){
        setIsZoomed(false)
        setTimeout(()=>{
          setDisplay(!display)
        },550)
                     }else{
                       setDisplay(!display)
                     }
    }
  };
  const handleTouchMove = (event) => {
    event.preventDefault(); // Prevent scrolling while swiping
  };

  return   <div style={{width:props.width,height:props.height,minWidth:props.minWidth,minHeight:props.minHeight,
  backgroundImage:'url('+image1+')',
  backgroundRepeat:'no-repeat',
  backgroundPosition: 'center',
  backgroundSize:'cover',position:'relative',
  display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:'#e5e5e5'
}}   >
  {/* <h4>Community</h4> */}
  <Helmet>
  <title> {'Karibu Konnect'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
      {(btn_txt=='' || btn_txt==null)  && <div className="activity" style={{zIndex:15}}></div>}
  {display && <div className='frost6'   onClick={()=>{
                if(display==true){
                 setIsZoomed(false)
                 setTimeout(()=>{
                   setDisplay(!display)
                 },550)
                              }else{
                                setDisplay(!display)
                              }
                            
 
           }} style={{height:'100vh',width:'100vw',top:0,left:0,position:'fixed',zIndex:15}}></div>}
       
 {display && <div           ref={swipeRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove} className={`zoom-center-container ${isZoomed ? 'zoomed' : ''}`} >
  {/* <button className='btn' onClick={()=>{setDisplay(!display)}} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
  <div    onClick={()=>{
               if(display==true){
                setIsZoomed(false)
                setTimeout(()=>{
                  setDisplay(!display)
                },550)
                             }else{
                               setDisplay(!display)
                             }
                           

          }} style={{height:'100vh',top:0,left:0,position:'absolute',zIndex:-1}}></div>
          <div  onClick={()=>{
               if(display==true){
                setIsZoomed(false)
                setTimeout(()=>{
                  setDisplay(!display)
                },550)
                             }else{
                               setDisplay(!display)
                             }
                           

          }}  style={{width:'100%',textAlign:'right',height:50}}>
          <div >
        <CloseIcon style={{ color: 'white' }} />
        </div>
            </div>
  {props.data?.sections?.map((img, i) => {
    
        return (
       <div  key={i}  className='galleryCard'   style={{width:'50%',maxWidth:600,minWidth:340,position:'relative'}} >
         <img  style={{width:'100%',border:'1px solid white',padding:0,maxWidth:600,minWidth:340}} src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.content_object.main_image} alt="" />
  <div onClick={()=>{
               var s=isYouTubeLink(img.content_object.redirect_url)
             
             if(s==true)
             {
               setModal(true);
               setVideo(img.content_object.redirect_url)
             }else{
              const baseURL = window.location.origin;
              const indexOfBaseURL = img.content_object.redirect_url.indexOf(baseURL);
                 if (indexOfBaseURL > -1) {
                 
                     navigate(img.content_object.redirect_url.slice(indexOfBaseURL + baseURL.length))
                 } else {
                   window.location.href =img.content_object.redirect_url
             
                 }
             }


          }} style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div>
<div style={{padding:5,marginBottom:10,color:'white',background:'#C62828',width:'100%',textAlign:'left'}}>
{img.content_object.button_text}
  
</div>
        </div>
         
        );
      })}
    
  </div>}
<div style={{  position: 'absolute',  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
  top: 0,
  left: 0,
  width: '100%',
  height:' 100%',

  backdropFilter: 'blur(80px)'}}></div>{props.data?.sections?.length>0 && <Slider4  height={props.height}  showAll={()=>{
                if(display==true){
                 setIsZoomed(false)
                 setTimeout(()=>{
                   setDisplay(!display)
                 },550)
                              }else{
                                setDisplay(!display)
                              }
                            
 
           }} setBtn_url={setBtn_url}  setBtn_text={setBtn_text} getImage={(value)=>{setImage1(value)}} data={props.data}/>  }
{/* 
<div  style={{  position: 'absolute',zIndex:5,
  bottom: 0,
  left: 0,
  width: '100%',
  height:' 100%'}}></div> */}

{btn_txt!='' && <div style={{width:'100%',background:'rgba(217, 30, 24,0.6)',zIndex:10,position:'absolute',bottom:0,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
          <button className='btn'  onClick={()=>{
               var s=isYouTubeLink(btn_url)
               console.log(btn_url)
             if(s==true)
             {
               setModal(true);
               setVideo(btn_url)
             }else{
              const baseURL = window.location.origin;
              const indexOfBaseURL = btn_url.indexOf(baseURL);
                 if (indexOfBaseURL > -1) {
                 
                     navigate(btn_url.slice(indexOfBaseURL + baseURL.length))
                 } else {
                   window.location.href =btn_url
             
                 }
            
             }


          }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{btn_txt}</button>
           {/* <button className='btn' onClick={()=>{
             if(display==true){
setIsZoomed(false)
setTimeout(()=>{
  setDisplay(!display)
},1000)
             }else{
               setDisplay(!display)
             }
           
            
            }} style={{background:'white',color:'black',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>test</button> */}
        </div>}
        
        {modal && <div style={{ width: '100vw', zIndex: 40, height: '100vh', background: 'rgba(0,0,0,0.8)', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
        <div onClick={() => { setModal(!modal) }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
        <div style={{background:'black',width:'95%',maxWidth:800,minWidth: 360,height:'50vw',maxHeight:400,zIndex:51}}>
      <iframe
            style={{ minHeight: 230, width: '100%', minWidth: 300, height: '100%' }}
                  src={`https://www.youtube.com/embed/`+getYouTubeVideoId(video)+'?rel=0'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>}
     
</div>
 }
export default Topnews;