/* eslint eqeqeq: 0 */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
export const CustomerGroups = () => {
  const [error, setError] = useState("");
  // const [valid, setValid] = useState(false);
  const [msg1, setMsg1] = useState("");
  const [status, setStatus] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [customerInfo, setCustomerInfo] =useState({"id":"","mobilenumber":"","firstname":"","lastname":"","address1":""})

  const [customerPhone, setCustomerPhone] = useState("");
  const [number, setNumber] = useState("");
  const refresh=()=>{

    setOrders([])
setNumber('');
setGroups([]);
    setCustomerInfo({"id":"","mobilenumber":"","firstname":"","lastname":"","address1":""})
    setSuccessMsg('');
    setMsg1('')
    setStatus("");
    setError("");
    setCustomerPhone("");
    // setValid(false)

    setSuccess(false)
  }

  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(248, 248, 248,1)';
  }, []);
  const BASE_URL = 'http://app.sasakonnect.net:23016';
 const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxODQyNTI0NjEzLCJpYXQiOjE2ODQ4NDQ1NjEsImp0aSI6ImE1ZGVlNzE5ZjRjNjQ0NmM5NzhiMTJjMDNlZjUwMmU0IiwidXNlcl9pZCI6M30.GHtisuQE5X_mpQO2oFFtd1PXG_IORE1QD-dGqY-zYjs';

 const fetchCustomer= async (phone,nw) => {
  if(nw==true){
    setSuccess(false)

      setError("");
  }

  var obj=  {
    "operation":"getcustomergroups",
    "customer_number":phone
}
    try {
      const response = await axios.post("https://food.sasakonnect.net/customer-group-vouchers", obj, {
        headers: {
          Authorization: `Bearer `+TOKEN
        },
      });
//   console.log(response.data)   
setNumber(customerPhone);
      if (response.status === 201 || response.status === 200) {
        if(response.data.result==false){
setError('Phone number does not exist. Please confirm the phone number')
        }else{
     
          setCustomerInfo({"id":response.data.customer_info.id,"mobilenumber":response.data.customer_info.mobilenumber,"firstname":response.data.customer_info.firstname,"lastname":response.data.customer_info.lastname,"address1":response.data.customer_info.address1 ?? ''})
setGroups(response.data.groups)
          // setValid(true)
          setStatus(response.data.result)
        //   setOrders(response.data.data)
        }
            } 
    } catch (error) {
      console.error(error);

      return false;
    }
  };
  const [successMsg, setSuccessMsg] = useState("");
  const addCustomerToGroup=async () => {
    setLoading(true)
    setError("")
    setSuccess(false)
    setSuccessMsg('');
var obj={
    "operation":"addcustomertogroup_ignoco",
    "customer_number":customerPhone,
    "customer_group":5

}
 try {
  const response = await axios.post("https://food.sasakonnect.net/customer-group-vouchers", obj, {
    headers: {
      Authorization: `Bearer `+TOKEN
    },
  });

  if (response.status === 201 || response.status === 200) {
    if(response.data.result==true){
  // console.log(response.data)
   setSuccess(true)
   setSuccessMsg('Customer added to group successfully')
   fetchCustomer(customerPhone,false)

    }else{
      setError(response.data.msg)
      setSuccess(false)
    }
    setLoading(false)
        } 
} catch (error) {
  console.error(error);
  setLoading(false)
  return false;
}


  } 

  const removeFromGroup=async () => {
    setLoading(true)
    setError("")
    setSuccess(false)
    setSuccessMsg('');
var obj={
    "operation":"removecustomerfromgroup",
    "customer_number":number,
    "customer_group":5

}
 try {
  const response = await axios.post("https://food.sasakonnect.net/customer-group-vouchers", obj, {
    headers: {
      Authorization: `Bearer `+TOKEN
    },
  });

  if (response.status === 201 || response.status === 200) {
    if(response.data.result==true){
  // console.log(response.data)
   setSuccess(true)
   fetchCustomer(number,false)
   setSuccessMsg('Customer removed from group successfully')
    }else{
      setError(response.data.msg)
      setSuccess(false)
    }
    setLoading(false)
        } 
} catch (error) {
  console.error(error);
  setLoading(false)
  return false;
}


  } 


  return (
    <div>
<h3 style={{margin:20}}><u>Customer groups</u></h3>
<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%',maxWidth:'1536px'}}>

  <div style={{margin:15,width:'50%',padding:10,minWidth:200,maxWidth:400}} className='block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 light:bg-gray-800 light:border-gray-700 light:hover:bg-gray-700'>
{/* Customer number */}
{/* <form> */}

        <div>

            <label  className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Customer Phone Number</label>
            <input value={customerPhone} onChange={(e)=>{setCustomerPhone(e.target.value)}} type="tel" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-blue-500 light:focus:border-blue-500" placeholder="Enter the phone number" />
        </div>
      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:10}}>
    <button   onClick={()=>{refresh()}} style={{visibility:status!=""?'visible':'hidden'}} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
  Clear
</button>  
        <button onClick={()=>{
        if(customerPhone.trim()!=''){
           fetchCustomer(customerPhone,true)
        }


            }}


              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
  Get Info
</button>
</div> 
{status!='' && <div style={{cursor:'pointer'}}>

<strong>Customer Info</strong>

<p>{msg1!='' && <strong style={{color:'red',fontSize:14}}>{msg1}</strong>}</p>
{msg1=='' && <div>
<p>ID:<small style={{fontWeight:'bold'}}> {customerInfo.id}</small></p>
<p>Name:<small style={{fontWeight:'bold'}}> {customerInfo.firstname} {customerInfo.lastname}</small></p>
<p>Phone numer:<small style={{fontWeight:'bold'}}> {customerInfo.mobilenumber}</small></p>
<p>Address:<small style={{fontWeight:'bold'}}> {customerInfo.address1}</small></p></div>}


</div>}

  </div>

{/* <div style={{height:40,flex:1}}></div> */}
    <div style={{flex:1,margin:15,padding:10,visibility:groups.length>0 ?'visible':'hidden',maxWidth:700}} className='block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 light:bg-gray-800 light:border-gray-700 light:hover:bg-gray-700'>

    {groups.length==0 &&   status=="True" &&<div style={{color:'rgba(75,75,75,0.6)',textAlign:'center',visibility:'visible'}}> <h2>No Prizes</h2></div>}

    <strong>Customer groups</strong>
    <hr style={{marginTop:10}}/>
  <div className="relative overflow-x-auto shadow-md sm:rounded-lg"  style={{maxHeight:300,overflow:'scroll'}}>
    <table style={{maxHeight:200}}  className="w-full text-sm text-left text-gray-500 light:text-gray-400" >
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
           <tr>
                <th scope="col" className="px-3 py-3">
                    ID
                </th>
                <th scope="col" className="px-3 py-3">
                    NAME
                </th>
              
               
        
                <th scope="col" className="px-3 py-3">
Remove from group
                </th>
            </tr>
        </thead>
        <tbody>


            {groups.map((group,id)=>{

              return(<tr key={id} className="border-b bg-gray-50 light:bg-gray-800 light:border-gray-700">

                <th scope="row" className="px-3 py-3 font-medium text-gray-900 whitespace-nowrap light:text-white">
                  {group.group_id}
                </th>
                <td className="px-3 py-3">
                  {group.group_name}
                </td>
        
               <td className="px-3 py-3">
                {

               }
          
                {group.group_id!=3 && <button onClick={()=>{
                    // removeFromGroup(group.group_id)
                    }}  style={{marginLeft:5,opacity:0.5}} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
Remove
</button>}

 
                </td>  
                  </tr>);
            })}




        </tbody>
    </table>
   
</div>
 <button onClick={()=>{
    if(!groups.some(item => item.group_id === 5)){
          addCustomerToGroup()
    }
  
    
    
    }}  style={{marginLeft:5,opacity:groups.some(item => item.group_id === 5)?0.5:1,marginTop:20,float:'right'}} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
Add to Group
</button>
    </div>
</div>


{/* ////////////////////// */}
<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>




{success &&  <div style={{height:50,margin:20}} className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">

  <span className="block sm:inline">{successMsg}<span role="img" aria-labelledby="ok">👍.</span></span>

</div>}
{error!="" &&  <div style={{height:50,margin:20}} className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">

  <span className="block sm:inline">{error}</span>

</div>}



  </div>



  {/* ////////////////////////////////////////////////// */}



      {loading==true && <div style={{width:'100vw',height:'100vh',background:'rgba(0,0,0,0.7)',position:'fixed',left:0,top:0,display:'flex',justifyContent:'center',alignItems:'center'}}>

      <div role="status">
    <svg aria-hidden="true" size={150} className="inline w-8 h-8 mr-2 text-gray-200 animate-spin light:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span className="sr-only">Loading...</span>
</div>
      </div>}
    </div>
  );
};
export default CustomerGroups