import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fetchFaq} from '../Api';
import ReactHtmlParser from 'react-html-parser'; 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet';
const FAQ = () => {

const [faqs,setFaqs]=useState([])
const [modal,setModal]=useState(false)
const [modal2,setModal2]=useState(false)
const [modalTitle,setModalTitle]=useState(false)
const [section,setSection]=useState([])
const [sectionPage,setSectionPage]=useState(1)
const [searchTerm, setSearchTerm] = useState('');
const [loading, setLoading] = useState(false);
const [video, setVideo] = useState('');

useEffect(()=>{
  const getFaq = async () => {
    try {
      setLoading(true)
      fetchFaq().then(data=>{
  // console.log(data);
  const updatedFaqs = data.results.map((faq) => {
  
      return { ...faq, class: 'expand' };
 
  });
  setFaqs(updatedFaqs);
    setLoading(false)
  });
      // set(data.results);
    
     
    } catch (error) {
      setLoading(false)
      console.log(error);
    }}
    getFaq();
    
   
},[])
useEffect(() => {
  window.scrollTo(0, 0);

 },[])

const handleToggle = (index) => {
  const updatedFaqs = faqs.map((faq, i) => {
    if (i === index) {
      if(faq.class=='expand2'){
        return { ...faq, class:'expand' };
      }else{
         return { ...faq, class:'expand2' };
      }
     
    } else {
      return  { ...faq, class:'expand' };
    }
  });
 
  setFaqs(updatedFaqs);
  
  // console.log(faqs)  
};
const [isFocused, setIsFocused] = useState(false);

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  setIsFocused(false);
};

  return (
    <div style={{display:'flex',flexDirection:'row',fontSize:12,flexWrap:'wrap',userSelect:'none',minHeight:'100vh',justifyContent:'center'}}>

<Helmet>
  <title> {'Karibu Konnect | Help'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>

     <div> 
     <div style={{display:'flex',marginBottom:10,width:'100vw',maxWidth:800,minWidth:340,paddingLeft:5,background:'white', flexDirection:'row',alignItems:'center'}}>
  <SearchIcon/>
   <input spellCheck="false"       onFocus={handleFocus}
        onBlur={handleBlur}
          onChange={(e)=>{setSearchTerm(e.target.value);}} value={searchTerm}   style={{height:30,width:180,margin:10,background:'white',border:'1px solid #FFD54F'  ,  borderColor: isFocused ? '#FFD54F':'#FFD54F',marginLeft:0,padding:5}} placeholder='search'/>    
</div>
      {loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'images/loader.gif'}  height={80} />
  </div>
    }
      <div className="ui styled accordion" style={{width:'100%',maxWidth:'100vw',minWidth:200,display:'flex',flexDirection:'column',padding:5,border:'10px solid white',background:'#f8f8f8',}}>
  

      {faqs.filter(str => {
  if (new RegExp(searchTerm, 'i').test(str.title)) {
    return true;
  }
  return false;
}).map((faq,index)=>{
        return ( <div key={index} >
          <div className={`title faq_title`} style={{fontWeight:'bold',cursor:'pointer'}} >
            <i className="dropdown icon"></i>
            {faq.title}
          </div>
    
          <div className={`content`}>
          {faq.category== "faq_with_images" && <div><div style={{lineHeight:'18px',height:'36px',overflow:'hidden',marginBottom:0}}>
            
            {ReactHtmlParser(faq.main_section_msg) }
         
 
          </div>  <div className='card_title' style={{color:'#FF8F00',cursor:'pointer'}} onClick={()=>{
              setSectionPage(1)
              setModal(true)
              // setInd(index)
              setSection(faq.sections)
              setModalTitle(faq.title)
              handleToggle(index)
             }}>View steps</div></div>}
             
                   {faq.category== "longQA" &&  <div><div style={{lineHeight:'18px',height:'36px',overflow:'hidden',marginBottom:0}}>
            
            {ReactHtmlParser(faq.main_section_msg) }
            {/* <video height='200px'  src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +faq.video_file} /> */}
           
 
          </div>  <div className='card_title'  style={{color:'#FF8F00',cursor:'pointer'}} onClick={()=>{
              handleToggle(index)
   
             }}>Read more</div></div>}
              {faq.category== "faq_with_video" &&  <div>     <div className='card_title'  style={{color:'#FF8F00',cursor:'pointer'}}  onClick={()=>{
              setSectionPage(1)
              setModal2(true)
              // setInd(index)
              setSection(faq.sections)
              setVideo(faq.video_file)
              setModalTitle(faq.title)
              // handleToggle(index)
             }}>Watch Video</div><div>
          <div style={{margin:5}}>  
      
      
            </div>
     </div>
   
           
           </div>}
               {faq.category== "shortQA" &&  <div>
            
            {ReactHtmlParser(faq.main_section_msg) }
         
            
          </div>}
            {/* <div>We accept returns within 30 days of purchase for items that are unused and in their original packaging</div> */}
        {faq.category== "longQA" &&   <div className={faq.class}>
          <div style={{margin:5}}>  
              {/* <video height='200px'  src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +faq.video_file} /> */}
              {ReactHtmlParser(faq.main_section_msg) }
            </div>
     
   
           
           </div>}
          </div>
         {faqs.length-1!=index && <hr/>}
        </div>)
      })}
    </div>  
  </div>
  
  {modal &&  <div style={{width:'100%',height:'100vh',background:'rgba(0,0,0,0.7)',justifyContent:'center',paddingTop:60,position:'fixed',left:0,top:0,display:'flex',overflow:'hidden',flexDirection:'column',alignItems:'center'}}>
<div onClick={()=>{setModal(false)}} style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

  </div>
<div  style={{width:'95%',position:'relative',minHeight:360,background:'white',zIndex:2,maxWidth:600,borderRadius:8,padding:10,display:'flex',flexDirection:'column'}}>
  <div onClick={()=>{setModal(false)}} style={{position:'absolute',width:50,height:50,top:10,right:10,zIndex:30,textAlign:'right'}}>
<CloseIcon  style={{color:'black'}} />
    </div>
<div className={`title faq_title`} style={{fontWeight:'bold',cursor:'pointer',fontSize:16,paddingRight:30}} >
          <i className="dropdown icon"></i>
          {modalTitle}
        </div>
        <hr/>
        <div style={{width:'100%',textAlign:'center',fontSize:16,fontWeight:'bold'}}>Step {sectionPage}/{section.length}</div>
        <div style={{width:'100%',fontSize:14,textAlign:'left'}}>{ReactHtmlParser(section[sectionPage-1].msg)}</div>

        <div style={{width:'100%',display:'flex',flexDirection:'row',flex:1,minHeight:300,justifyContent:'space-between'}}>
          
          {/* <div style={{background:'#FFCC80',display:'flex',flexDirection:'column',justifyContent:'center',padding:10}}>

</div> */}
<div style={{flex:1,
  backgroundImage:'url('+'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +section[sectionPage-1].image+')',
  backgroundRepeat:'no-repeat',
  backgroundPosition: 'center',
  backgroundSize:'contain',position:'relative',
  display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'
  }}>

    </div>

          {/* <div style={{background:'#FFCC80',display:'flex',flexDirection:'column',justifyContent:'center',padding:10}}>

</div> */}
        </div>
        <div style={{width:'100%',display:'flex',justifyContent:'space-between',padding:10,zoom:0.8}}>

        {sectionPage>1 &&  <button onClick={()=>{setSectionPage(sectionPage-1)}}  className='btn btn-outline-primary '><ArrowBackIcon />Previous</button>}
        <div style={{flex:1}}></div>
          {sectionPage<section.length && <button onClick={()=>{setSectionPage(sectionPage+1)}} className='btn btn-primary'>Next step<ArrowForwardIcon /></button>}
        </div>
  </div>
</div>}




{modal2 &&  <div style={{width:'100%',height:'100vh',background:'rgba(0,0,0,0.7)',justifyContent:'center',paddingTop:60,position:'fixed',left:0,top:0,display:'flex',overflow:'hidden',flexDirection:'column',alignItems:'center'}}>
<div onClick={()=>{setModal2(false)}} style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

  </div>
<div  style={{width:'95%',position:'relative',minHeight:360,background:'white',zIndex:2,maxWidth:600,borderRadius:8,padding:10,display:'flex',flexDirection:'column'}}>
  <div onClick={()=>{setModal2(false)}} style={{position:'absolute',width:50,height:50,top:10,right:10,zIndex:30,textAlign:'right'}}>
<CloseIcon  style={{color:'black'}} />
    </div>
<div className={`title faq_title`} style={{fontWeight:'bold',cursor:'pointer',fontSize:16,paddingRight:30}} >
          <i className="dropdown icon"></i>
          {modalTitle}
        </div>
        <hr/>
        {/* <div style={{width:'100%',textAlign:'center',fontSize:16,fontWeight:'bold'}}>Step {sectionPage}/{section.length}</div>
        <div style={{width:'100%',fontSize:14,textAlign:'left'}}>{ReactHtmlParser(section[sectionPage-1].msg)}</div> */}

        <div style={{width:'100%',display:'flex',flexDirection:'row',flex:1,minHeight:300,justifyContent:'space-between'}}>
          
          {/* <div style={{background:'#FFCC80',display:'flex',flexDirection:'column',justifyContent:'center',padding:10}}>

</div> */}
<div style={{
  // flex:1,
  // backgroundImage:'url('+'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +section[sectionPage-1].image+')',
  backgroundRepeat:'no-repeat',
  backgroundPosition: 'center',
  backgroundSize:'contain',position:'relative',
  display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',width:'100%',textAlign:'center'
  }}>

 <video style={{width:'100%'}} controls src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +video} />
    </div>

          {/* <div style={{background:'#FFCC80',display:'flex',flexDirection:'column',justifyContent:'center',padding:10}}>

</div> */}
        </div>
    
  </div>
</div>}
    </div>
 
  );
}

export default FAQ;
