import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Downloads = () => {
 

    
  return (
    <div style={{display:'flex',flexDirection:'row',fontSize:12,flexWrap:'wrap'}}>
       <Helmet>
  <title> {'Karibu Konnect | Downloads'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
   <Link to='https://play.google.com/store/apps/details?id=net.sasakonnect.foodplus&hl=en_US'> <h3>Download FoodPlus App</h3></Link>
  <br/>
  <div style={{width:'100%',textAlign:'center'}}> 
  <Link to='https://play.google.com/store/apps/details?id=net.sasakonnect.foodplus&hl=en_US'>  <img alt='img' style={{width:'95%',maxWidth:600}} src={'https://karibu.sasakonnect.net/media/media/img/events/Untitled-1_dblXml8.jpg'}/>
 </Link>  </div>
 <br/> <br/>
 <Link to='https://play.google.com/store/apps/details?id=net.sasakonnect.konnect&hl=en_US'>  <h3>Download Konnect Internet App</h3></Link><br/>
    <div style={{width:'100%',textAlign:'center'}}>   <Link to='https://play.google.com/store/apps/details?id=net.sasakonnect.konnect&hl=en_US'> 
     <img alt='img' style={{width:'95%',maxWidth:600}} src={'./images/banner.jpg'}/></Link>
   </div>

    </div>
 
  );
}

export default Downloads;
