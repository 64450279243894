/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React,{useRef,useEffect,useState} from 'react';
import '../App.css'; // Import your CSS file for styling
import { useLocation,Link } from 'react-router-dom';
import ContactUsModal from './contact'
// import Chat from './Chat';
const NavBar=(props)=> {


  const elementRef = useRef(null);
  const [isSnapped, setIsSnapped] = useState(false);
  const [originalTop, setOriginalTop] = useState(0);
  const location = useLocation();

  const baseUrl = location.pathname.split('/')[0];

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      setOriginalTop(element.getBoundingClientRect().top + window.scrollY);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 0 && !isSnapped) {
          setIsSnapped(true);
          element.style.position = 'fixed';
          element.style.top = '0';
        } else if (window.scrollY <= originalTop && isSnapped) {
          setIsSnapped(false);
          element.style.position = '';
          element.style.top = '';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSnapped, originalTop]);

  const classNames = `my-element${isSnapped ? ' snapped' : ''}`;

const [visible, setVisible] = useState(false);

  const toggleNav = () => {
    setVisible(!visible);

    // console.log(visible)
  };

  return (
    <div >
      {/* <Chat/> */}
    <nav className="navbar" style={{background:'white',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',overflow:'hidden',zIndex:50,position:'relative',paddingBottom:0}}>
  {visible && <div className='d-sm-none'  style={{width:'100vw',zIndex:62,height:'100vh',background:'rgba(0,0,0,0.7)',position:'fixed',top:0,left:0,display:'flex',alignItems:"center",justifyContent:'center'}}>
     <div onClick={()=>toggleNav()} style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}></div>
  </div>}
  {/* <SideNav toggle={showMenu}/> */}

  <div className={ visible ? 'sidenav sidenav-open d-sm-none' : 'sidenav sidenav-closed d-sm-none'}>
      {/* <button onClick={()=>toggleNav()}>Toggle Nav</button> */}
      <div style={{padding:20}}>

	    <img alt='' className='d-sm-none' height={30} style={{marginTop:5}} src={baseUrl+'/images/logo3.png'} />
      <br/>
      <hr/>
      <ul className="navbar-nav">
	      <li className="nav-item active">
	        <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
	      </li>
	      <li className="nav-item">
	        <Link className="nav-link" to='/community'>Community</Link>
	      </li>
        <li className="nav-item" >
	        <Link className="nav-link" to='/deals'>Foodplus Deals <img alt='img' width={25} src={baseUrl+'/images/deal.png'} /></Link>
	      </li>
        <li className="nav-item" >
	        <Link className="nav-link" to='/videos'>Videos </Link>
	      </li>
	      {/* <li className="nav-item">
	        <Link className="nav-link" to='/about'>About</Link>
	      </li> */}
        <li className="nav-item">
	        <Link className="nav-link" to='/gallery'>Gallery</Link>
	      </li>
	      <li className="nav-item">
	        <Link className="nav-link" to='/faq'>Help</Link>
	      </li>
        <li className="nav-item">
	        <Link className="nav-link" to='/downloads'>Download Apps</Link>
	      </li>
        <li className="nav-item">
          <br/>
        {/* <div className='navoption2 d-none d-sm-block'> */}
        <ContactUsModal/>



{/* </div> */}
	      </li>
      
	    </ul>
	  </div>
      {/* Nav content goes here */}
    </div>
   <div style={{maxWidth:1366,width:'100%',display:'flex',flexDirection:'column',}}>

    <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
        <div style={{flex:1,display:'flex',justifyContent:'center',flexDirection:'column'}}>
        <Link to='/'><img alt='' className='d-none d-sm-block' height={50} style={{marginLeft:10,marginTop:5}} src={baseUrl+'/images/logo3.png'} />
           <img alt='' className='d-sm-none' height={30} style={{marginLeft:10,marginTop:5}} src={baseUrl+'/images/logo3.png'} />
      </Link> 
        </div>
        
        <div  style={{flex:1,cursor:'pointer',paddingTop:10,display:'flex',flexDirection:'row',justifyContent:'end'}}>
      <Link to='https://portal.sasakonnect.net/packages' className='comp d-none d-sm-block' style={{flex:1}}> 
       
  <img alt='' height={30} style={{}} src={baseUrl+'/images/plans.png'} />
<strong className='btnlink'  style={{color:'black',fontSize:11}}>Internet Plans</strong>
{/* <img alt='' height={17} style={{}} src={baseUrl+'/images/a1.png'} /> */}
</Link>
<Link to='https://portal.sasakonnect.net/add-device' className='comp d-none d-sm-block' style={{flex:1}}>
    <img alt='' height={30} style={{}} src={baseUrl+'/images/add_device.png'} />
     {/* <img alt='' height={17} style={{}} src={baseUrl+'/images/a2.png'} /> */}
   <strong className='btnlink'  style={{color:'black',fontSize:11}}>Add device</strong>
     
      </Link>
<Link to='https://portal.sasakonnect.net/change-device' className='comp d-none d-sm-block'style={{flex:1}}>  
 <img alt='' height={30} style={{}} src={baseUrl+'/images/change_device.png'} />  
  {/* <img alt='' height={17} style={{}} src={baseUrl+'/images/a3.png'} /> */}
  <strong className='btnlink' style={{color:'black',fontSize:11}}>Change device</strong>
  </Link>
        </div>
        <div  className='comp d-sm-none'style={{cursor:'pointer',alignItems:'center',flex:1,display:'flex',flexDirection:'row',justifyContent:'end',height:50}}>  
        <ContactUsModal/>
      <button onClick={toggleNav}  className="navbar-toggler d-sm-none" type="button" data-toggle="collapse" data-target="#navbarNav">
      <i className="fas fa-bars" style={{fontSize:24}}></i>
	  </button>
  </div>
      </div>
      <div  style={{display:'flex',flexDirection:'row'}}>
        <div className='d-none d-sm-block' style={{height:35}}></div>
      <div   ref={elementRef} className={classNames} style={{display:'flex',flexDirection:'row',}}>
        {/* <div  style={{height:50,width:'100%',position:'absolute',zIndex:0,}}></div> */}
        <Link to='/' style={{display:'flex',justifyContent:'center',flexDirection:'column'}}><img alt='' className='logo' height={30} style={{marginLeft:10,display:'none',marginTop:5}} src={baseUrl+'/images/logo3.png'} />
       </Link>
       
       {/* <div style={{flex:1}}></div> */}
      
        
      <div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='home' ?'bold':'normal',borderBottom: props?.title=='home' ?'5.0px solid orange':'0px'}}>
        <Link to='/' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>  Home
        {props.title!='home' && <div className="child"></div>}</Link>
        </div>
<div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='community' ? location.pathname.split('/').length<3?'bold':'normal':'normal',borderBottom:props?.title=='community' ? location.pathname.split('/').length<3?'5.0px solid orange':'0px':'0px'}}>
   <Link to='/community' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>
      
  Community
{props.title!='community'  && <div className="child" ></div>}</Link>
</div>
<div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='deals' ? location.pathname.split('/').length<3?'bold':'normal':'normal',position:'relative',borderBottom:props?.title=='deals' ? location.pathname.split('/').length<3?'5.0px solid orange':'0px':'0px'}}>
   <Link to='/deals' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>
      
   Deals<img  alt='img' width={25} style={{position:'absolute',right:0}} src={baseUrl+'/images/deal.png'}  />
{props.title!='deals'  && <div className="child" ></div>}</Link>
</div>


<div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='videos' ? location.pathname.split('/').length<3?'bold':'normal':'normal',borderBottom:props?.title=='videos' ? location.pathname.split('/').length<3?'5.0px solid orange':'0px':'0px'}}>
   <Link to='/videos' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>
      
 Videos
{props.title!='videos'  && <div className="child" ></div>}</Link>
</div>
{/* <div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='about' ? location.pathname.split('/').length<3?'bold':'normal':'normal',borderBottom:props?.title=='about' ? location.pathname.split('/').length<3?'5.0px solid orange':'0px':'0px'}}>
   <Link to='/about' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>
      
  About
{props.title!='about'  && <div className="child" ></div>}</Link>
</div> */}
<div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='gallery' ? location.pathname.split('/').length<3?'bold':'normal':'normal',borderBottom:props?.title=='gallery' ? location.pathname.split('/').length<3?'5.0px solid orange':'0px':'0px'}}>
   <Link to='/gallery' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>
      
  Gallery
{props.title!='gallery'  && <div className="child" ></div>}</Link>
</div>
<div className='navoption2 d-none d-sm-block' style={{fontWeight:props?.title=='faq' ? location.pathname.split('/').length<3?'bold':'normal':'normal',borderBottom:props?.title=='faq' ? location.pathname.split('/').length<3?'5.0px solid orange':'0px':'0px'}}>
   <Link to='/faq' style={{display:'block',textDecoration:'none',color:'black',width:'100%',height:'100%'}}>
      
Help
{props.title!='faq'  && <div className="child" ></div>}</Link>
</div>
<div className='navoption2 d-none d-sm-block'>
<div style={{marginBottom:5}}><ContactUsModal/></div>

</div>

<div className='logo'   style={{display:'none',flexDirection:'row',flex:1,justifyContent:'flex-end'}}>
<div  style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'flex-end',alignItems:'center',height:50}}>
  <div  className="d-sm-none">
<ContactUsModal/>
  </div>

<button onClick={toggleNav}  className="navbar-toggler d-sm-none" type="button" data-toggle="collapse" data-target="#navbarNav">
	    <i className="fas fa-bars" style={{fontSize:24}}></i>
	  </button></div>
    
       </div> 
       
    
</div>
      </div>

    
      </div>
 </nav>
 <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
  <div style={{width:'100%',maxWidth:1366,padding:10,paddingTop:10,paddingBottom:10}}><div  style={{color:'red',flex:1,cursor:'pointer',display:'flex',flexDirection:'row',justifyContent:'center'}}>
      <Link to='https://portal.sasakonnect.net/packages' className='d-sm-none' style={{flex:1,textAlign:'center'}}> 
       
  <img alt='' height={30} style={{}} src={baseUrl+'/images/plans.png'} />
  <br/>
<strong style={{color:'black',fontSize:11}}>Internet Plans</strong>
{/* <img alt='' height={17} style={{}} src={baseUrl+'/images/a1.png'} /> */}
</Link>
<Link to='https://portal.sasakonnect.net/add-device' className='d-sm-none' style={{flex:1,textAlign:'center'}}> 
    <img alt='' height={30} style={{}} src={baseUrl+'/images/add_device.png'} />
     {/* <img alt='' height={17} style={{}} src={baseUrl+'/images/a2.png'} /> */} <br/>
   <strong style={{color:'black',fontSize:11}}>Add device</strong>
     
      </Link>
<Link to='https://portal.sasakonnect.net/change-device' className='d-sm-none' style={{flex:1,textAlign:'center'}}> 
 <img alt='' height={30} style={{}} src={baseUrl+'/images/change_device.png'} />  
  {/* <img alt='' height={17} style={{}} src={baseUrl+'/images/a3.png'} /> */} <br/>
  <strong style={{color:'black',fontSize:11}}>Change device</strong>
  </Link>
        </div></div>

 </div>

 </div>
  );
}

export default NavBar;
