/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import React, { useEffect, useState } from 'react';
import {useLocation,Link } from 'react-router-dom'
import { postComment,likeArticle} from '../Api';
import {

  FacebookShareButton,

  TwitterShareButton,


 
} from "react-share";
import {
 
  FacebookIcon,
    TwitterIcon,
    WhatsappIcon

} from "react-share";
import ContactUsModal from './contact'
const CommentSection = (props) => {
  const [like,setLike]=useState(false)
  const [likeCount,setLikeCount]=useState(0)
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');
const [loading,setLoading]=useState(false)
const [success,setSuccess]=useState(false)
useEffect(()=>{
  
  setLikeCount(props.likes)
},[])
useEffect(() => {
  const storedLike = localStorage.getItem(props.slug);
  if (storedLike) {
    setLike(storedLike === 'true');
  }

}, []);
const getlike= async(state)=>{
  try {
 
  likeArticle(props.slug,{'action':state}).then(()=>{
  //  console.log(data);


   })

 } catch (error) {
   console.log(error);
 }
}
const likeOperation = () => {
  if (like === false) {
  
    getlike('like').then(()=>{
  setLikeCount(likeCount + 1);
    setLike(true);
    localStorage.setItem(props.slug, true.toString()); 

    })
  } else {

    getlike('unlike').then(()=>{
  setLikeCount(likeCount - 1);
    setLike(false);
    localStorage.setItem(props.slug, false.toString());

    })
  }
};

  const handleSubmit = (event) => {
    event.preventDefault();
 
    if(loading==false){
     setLoading(true)
         const obj={
          name,
          phone,
          'message':comment,
        'event':props.id
        }
      
       postComment(obj,props.id).then(()=>{
        setName('')
        setComment('')
  setSuccess(true)
        setLoading(false)
        //  console.log(msg)
         props.getComments(props.id)
      
       })
     
        }



  };
    const location = useLocation();
        const currentUrl = location.pathname;
  // const handleShare = async () => {
  

  //   try {
  //     await navigator.share({
  //       url: currentUrl,
  //       title: 'title',
  //     });
  //   } catch (error) {
  //     console.error('Error sharing:', error);
  //   }
  // }
  return (
    <div className="container mt-5" >
    <div className="d-flex justify-content-center row">
        <div style={{maxWidth:800}}>
        <div style={{width:"100%",display:"flex",justifyContent:'start',paddingTop:10,paddingBottom:10}}>
        <ContactUsModal type='comment'/>
     
         </div>
            <div className="d-flex flex-column comment-section">
                 <div className="bg-white">
                    <div className="d-flex flex-row fs-12" style={{width:'100%',display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                        {/* <div className="like p-2 cursor"><i className="fa fa-thumbs-o-up"></i><span className="ml-1">Like</span></div>
                        <div className="like p-2 cursor"><i className="fa fa-commenting-o"></i><span className="ml-1">Comment</span></div> */}
                        <div className="like p-2 cursor"><i className="fa fa-share"></i>
                        <span className="ml-1" >
                          Share
                          <span style={{margin:2}}>
                           <FacebookShareButton url={window.location.origin+currentUrl}  quote={props.title}>
                          <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
                        </span>
                        <span style={{margin:2}}>
                        <TwitterShareButton url={window.location.origin+currentUrl} title={props.title}>
      <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
                        </span>       
                        <span style={{margin:2}}>
                        <Link  to={'whatsapp://send?text='+window.location.origin+currentUrl}>
      <WhatsappIcon size={32} round={true} />
      </Link>
                        </span>  
                          </span></div>
                   
                     {/* <div style={{flex:1}}></div> */}
      <div style={{display:'flex',flex:1,justifyContent:'flex-end',minWidth:200}}>
        <div style={{marginRight:5}} >({likeCount} Likes)</div>
        {!like && <div onClick={()=>{likeOperation();}} style={{border:'1px solid grey',userSelect:'none',color:'grey',paddingLeft:12,paddingRight:12,borderRadius:30}}>
        <i className="fa fa-heart" aria-hidden="true" style={{marginRight:5}}></i>
          Like</div>}

          {like && <div   onClick={()=>{likeOperation()}}  style={{border:'1px solid red',userSelect:'none',color:'red',paddingLeft:12,paddingRight:12,borderRadius:30}}>
        <i className="fa fa-heart" aria-hidden="true" style={{marginRight:5}}></i>
          Like</div>}
      </div>
                    </div>
                    
                </div>
           
                <div className="bg-light p-2">
                <form onSubmit={handleSubmit}>
                          <div className="d-flex flex-row align-items-start">
                            {/* <img className="rounded-circle" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" width="40"/> */}
                            <input  value={name} onChange={(e)=>{setName(e.target.value)}}  type="text" className="form-control ml-1 shadow-none" placeholder="Enter you name"/></div>
                          <br/>
                        {props.showPhone &&  <div className="d-flex flex-column ">
                            {/* <img className="rounded-circle" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" width="40"/> */}
                            <div>
                              <input  value={phone} onChange={(e)=>{setPhone(e.target.value)}}  type="text" className="form-control ml-1 shadow-none" placeholder="Enter you phone number"/>
                              </div>
                            
                            
                            <div>
                              <small style={{color:"gray"}}> {' '} (Please enter your phone number if reporting an issue)
                             </small>
                             </div>    <br/></div>}
                      
                           <div className="d-flex flex-row align-items-start">
                            {/* <img className="rounded-circle" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" width="40"/> */}
                            <textarea required value={comment} onChange={(e)=>{setComment(e.target.value)}}  className="form-control ml-1 shadow-none textarea" placeholder='Enter your comment...'></textarea></div>
        {success && <span style={{color:'green',paddingLeft:10}}>
          
          Your comment is under review
          
          </span>}
                    <div className="mt-2 text-right">
                      
                      
                    
                      {!loading ?   <button type="submit" className="btn btn-primary btn-sm shadow-none" >Post comment</button>: <button  className="btn btn-primary btn-sm shadow-none"  type="button">Sending ...</button>}
             
                      
                      
                      
                      
                      <button className="btn btn-outline-primary btn-sm ml-1 shadow-none" onClick={()=>{
                        setName('')
                        setComment('')
                      }} type="button">Cancel</button></div>
               </form>
                </div> 
         
                {props.comments.map((comment,i)=><div  key={i} className="bg-white p-2" style={{fontSize:14}}>
                    <div className="d-flex flex-row user-info"><img alt='img' className="rounded-circle" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" height={40} width="40"/>
                        <div className="d-flex flex-column justify-content-start ml-2"><span className="d-block font-weight-bold name">{comment.name}</span><span className="date text-black-50">{comment.date_created}</span></div>
                    </div>
                    <div className="mt-2" style={{fontSize:14}}>
                        <p className="comment-text">{comment.message}</p>
                    </div>
                    {comment.adminreply!=null && comment.adminreply!='' && 
                    <div style={{paddingLeft:20}}>

                    <div className="d-flex flex-row user-info"><img alt='img' className="rounded-circle" src="../favicon.ico" height={40} width="40"/>
                        <div className="d-flex flex-column justify-content-start ml-2"><span className="d-block font-weight-bold name" style={{color:'gray'}}>{comment.adminuser.name} (Admin)</span></div>
                    </div>
                    <div className="mt-2" style={{fontSize:14}}>
                        <p className="comment-text">{comment.adminreply}</p>
                    </div>
               
                    </div>
                    }
                </div>)}
               
            </div>
        </div>
    </div>
</div>
  );
};
  
 
  export default CommentSection