/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import '../App.css';
import { fetchVideo} from '../Api';
import React, { useState, useEffect,useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
function getYouTubeVideoId(url) {
  try {
    const youtubeUrl = new URL(url);
    const hostname = youtubeUrl.hostname;
    const pathname = youtubeUrl.pathname;

    const isYouTubeLink =
      (hostname === 'www.youtube.com' || hostname === 'youtube.com') &&
      pathname === '/watch' &&
      youtubeUrl.searchParams.has('v');

    if (isYouTubeLink) {
      return youtubeUrl.searchParams.get('v');
    } else if (hostname === 'youtu.be' && pathname !== '/' && youtubeUrl.pathname.split('/').length === 2) {
      return youtubeUrl.pathname.split('/')[1];
    } else {
      return url;
    }
  } catch (error) {
    // console.error('Invalid YouTube URL');
    return url;
  }
}
const Article = ({show}) => {
  const [url, setUrl] = useState('')
  const [modal, setModal] = useState(false)
  // const [announcements,setAnnouncements]=useState([])
  const [data, setData] = useState([]);
  useEffect(() => {
    const getVideo = async () => {
      try {
        // setLoading(true)
        const data = await fetchVideo('jikoni');
        setData(data.results);
        // console.log(data);
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    }
    getVideo()

    // const getAnnouncements = async () => {
    //   try {
    //     const data = await fetchAnnouncements();
    //     // console.log(data.results)
    //     setAnnouncements(data.results);
    //   } catch (error) {
    //     // Handle error
    //   }}
    //   getAnnouncements()
  }, []);


  const iframeRefs = useRef([]);

  useEffect(() => {
    // Function to refresh an individual iframe on error
    const refreshOnFailure = (iframeRef) => {
      // Add an event listener for the iframe's 'onError' event
      iframeRef?.current?.addEventListener('error', () => {
        // Check if there is an internet connection
        if (navigator.onLine) {
          iframeRef?.current?.contentWindow?.location.reload();
        }
      });
    };

    // Set up the 'onError' refresh for each iframe
    iframeRefs?.current?.forEach((iframeRef) => refreshOnFailure(iframeRef));

    // Clean up the event listeners on component unmount
    return () => {
      iframeRefs?.current?.forEach((iframeRef) => {
        iframeRef?.current?.removeEventListener('error', () => {});
      });
    };
  }, []);

  return (
    <div style={{width:'100%',maxWidth:'100vw',overflow:'hidden'}}>
           {show!=false &&  <div style={{ borderBottom: '2px solid #388E3C' }}>
        <div style={{ padding: 5, userSelect:'none',lineHeight: '20px', color: 'white', background: '#388E3C', width: 160 }}>Konnect Food+</div>

      </div>}
       <div className='scrollable-container ' style={{display:'flex',flexDirection:'row',overflow:'auto',width:'100%',maxWidth:'100vw',background:'#f8f8f8',margin:5}}>
       {data.map((item,i)=><div  key={i} style={{display:'flex',flexDirection:'row',padding:5,width:'33%',minWidth:300,maxWidth:400,position:'relative'}}>    <div onClick={()=>{setModal(true);setUrl(item.video_id)}} style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}></div>
 
 <div style={{flex:1,}}>
    {/* <div onClick={()=>{setModal(true);setUrl(item.video_id)}}
     style={{position:'relative',top:0,left:0,minHeight:230,minWidth:290,width:'100%',
              backgroundRepeat:'no-repeat  !important',
              backgroundPosition: 'center  !important',
              backgroundSize:'100% 100%  !important',
        background:'url(https://img.youtube.com/vi/'+item.video_id+'/0.jpg)'
        }}></div> */}
<div style={{}}
     // backgroundRepeat:'no-repeat',
     // backgroundPosition: 'center',
     // backgroundSize:'cover',
     // backgroundImage:data[4]?.main_image!=null ? `url(${data[4]?.main_image})`: `url(https://www.sasakonnect.com/images/bg/konnct_bannr_one.jpg)`}}
     >
      
<iframe
style={{minHeight:230,minWidth:290,width:'100%'}}
         src={`https://www.youtube.com/embed/`+getYouTubeVideoId(item.video_id)+'?rel=0'}
         frameBorder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         allowFullScreen
         title="Embedded youtube"
          ref={(ref) => (iframeRefs.current[i] = ref)}
       />
           </div>
          <br/>
           
            <h5 className="card-title card_title titlehover" style={{color:'black',fontSize:16}}>
   {item.title}
   </h5>
     {/* <p className="card-text">{item.article_section_main}</p> */}
       <div className='truncate-text' style={{fontSize:14,color:'gray'}}>
       {ReactHtmlParser(item.description)}
       </div>
   </div>
   
       </div>)}  </div>
   
      {modal && <div style={{width:'100vw',zIndex:40,height:'100vh',background:'rgba(0,0,0,0.8)',position:'fixed',top:0,left:0,display:'flex',alignItems:"center",justifyContent:'center'}}>
        <div onClick={()=>{setModal(!modal)}} style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}></div>
        <div style={{background:'black',width:'95%',maxWidth:800,minWidth: 360,height:'50vw',maxHeight:400,zIndex:51}}>
         <iframe
      style={{minHeight:230,width:'100%',minWidth:300,height:'100%'}}
         src={`https://www.youtube.com/embed/`+getYouTubeVideoId(url)}
         frameBorder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         allowFullScreen
         title="Embedded youtube"
        
       />
         </div>
       </div>}
   
       
   
   
       </div>
   
     );
   };
   
   export default Article;