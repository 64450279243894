import { useLocation ,Link} from 'react-router-dom';
import React from 'react';
// import ThemeButton from './toggleTheme'

const Footer=()=>{
  const location = useLocation();

  //  const isDarkModeInitial = JSON.parse(localStorage.getItem('isDarkMode')) || false;
  // const [isDarkMode, setIsDarkMode] = useState(isDarkModeInitial);
  // useEffect(() => {
   
  //   // Update localStorage whenever the state changes
  //   localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  // }, [isDarkMode]);
  const baseUrl = location.pathname.split('/')[0];
    return(
        <div style={{
            overflow:'hidden',
                    // backgroundImage: `url(https://www.sasakonnect.com//images/bg/konnct_bannr_three.jpg)`,
                    // background:'black',
                    background:'#F0F0F2',marginTop:50,
                    backgroundRepeat:'no-repeat',
            // height:'100vh',
            backgroundAttachment: 'fixed',justifyContent:'center',alignItems:'center',
          flexWrap:'wrap',
                    backgroundSize:'cover',padding:10,display:'flex',flexDirection:"column",paddingTop:40,
                    // filter: isDarkMode ?'invert(1)':'invert(0)'
                    // Specify your background image URL here
                  }}>
   {/* <div style={{width:'100%',maxWidth:1200,padding:5,background:'#F0F0F2',display:'flex',justifyContent:'flex-end',color:'black'}}>
<div style={{userSelect:'none'}}>Dark mode <br/>
<ThemeButton setIsDarkMode={setIsDarkMode}/>
</div>


</div> */}
            <div style={{width:'100%',maxWidth:1200,padding:10,background:'#F0F0F2',display:'flex',justifyContent:'center',color:'black'}}>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',textAlign:'center'}}>  
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                  <div style={{margin:5}}>
                    <img alt=''  src={baseUrl+'/images/location.png'} height='20px' />
                    Zimmerman</div>
                  <div style={{margin:5}}><img  alt='' src={baseUrl+'/images/location.png'} height='20px'  />Githurai 44</div>
                  <div style={{margin:5}}><img  alt='' src={baseUrl+'/images/location.png'} height='20px' />Thika</div>
                </div>
                <div style={{fontWeight:'bold'}}>Stay Konnected beyond limits</div>
            <div>© 2022 Ahadi Corporation</div></div>
            
            
            </div>
            <div style={{width:'100%',maxWidth:1200,padding:10,background:'#F0F0F2',display:'flex',paddingTop:30,flexWrap:'wrap',justifyContent:'space-between',alignItems:'end',color:'black',}}>
              <div style={{fontWeight:'bold'}}>Follow Us
              <div style={{display:'flex'}}>
              <Link target='_blank' rel="noreferrer" to='https://www.facebook.com/konnecthub'>  
    <div className='titlehover' style={{display:'flex',marginLeft:5,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
    <div style={{fontSize:14}}><img alt='' height={40} src={baseUrl+'/images/facebook.png'}/></div>
  
    </div></Link>
    <Link target='_blank' rel="noreferrer" to='https://twitter.com/KonnectInternet'>  
    <div className='titlehover' style={{display:'flex',marginLeft:5,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
    <div style={{fontSize:14}}><img alt='' height={40} src={baseUrl+'/images/twitter.png'}/></div>
    </div></Link>
    <Link target='_blank' rel="noreferrer" to='https://www.youtube.com/@KaribuKonnect'>  
    <div  className='titlehover' style={{display:'flex',marginLeft:5,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
      <div style={{fontSize:14}}><img alt='' height={40} src={baseUrl+'/images/youtube.png'}/></div>
      
    </div></Link>
    <Link target='_blank' rel="noreferrer" to='https://instagram.com/konnecthub?igshid=NTc4MTIwNjQ2YQ=='>  
    <div className='titlehover' style={{display:'flex',marginLeft:5,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
    <div style={{fontSize:14}}><img alt='' height={40} src={baseUrl+'/images/insta.png'}/></div>
    </div>
    </Link>
    </div>
              </div>
            <div>Call or Whatsapp<br/>
            +254701888666<br/>
            +254111221188</div>
            
            
            </div>
                    </div>
    )
}

export default Footer