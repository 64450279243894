import '../App.css';
import { fetchArticles} from '../Api';
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { useNavigate } from 'react-router-dom';
// import { fetchNotices } from '../Api';
const Article = () => {
const navigate =useNavigate();
  const [data, setData] = useState([]);

const [loading,setLoading]=useState(false)
// const [announcements,setAnnouncements]=useState([])
  useEffect(() => {
    const getArticles = async () => {
      try {
        setLoading(true)
      await fetchArticles().then(dt=>{
          setLoading(false);
           setData(dt.results);
            //  console.log(dt);
        });
       
      
          // 
      } catch (error) {
        setLoading(false)
        console.log(error);
      }}
      getArticles()


     
        // const getAnnouncements = async () => {
        //   try {
        //     const data = await fetchAnnouncements();
        //     // console.log(data.results)
        //     // setAnnouncements(data.results);
        //   } catch (error) {
        //     // Handle error
        //   }}
        //   getAnnouncements()
  }, []);

  return (
 <div style={{paddingTop:20}}>
  
{/* <img width='360px' src='http://172.18.0.42:4000/content/?file=3f9be09ab1eb571f9b070fb11396ebb3.jpeg' /> */}
{/* {announcements.map((ann,index)=>{
    if(ann.position=='above_maintenance'){
      return <div  key={index} style={{width:'100%',position:'relative',padding:8}}>

{ann.category!='video' ?<img src={ann.main_image} width='100%'/>:
 <iframe
 style={{minHeight:300,width:'100%',minWidth:300,}}
    src={`https://www.youtube.com/embed/`+ann.video_id}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
    }
       {ann.show_button && <div style={{width:'100%',background:'rgba(255,255,255,0.4)',position:'absolute',bottom:8,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
          <button className='btn' onClick={()=>{
             window.location.href = ann.redirect_url; 

          }} style={{background:'orange',color:'white',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{ann.button_text}</button>
        </div>}
      </div>
    }
})} */}
 
{/* {announcements.map((ann,index)=>{
    if(ann.position=='below_maintenance'){
      return <div key={index} style={{width:'100%',position:'relative',padding:8}}>

{ann.category!='video' ?<img src={ann.main_image} width='100%'/>:
 <iframe
 style={{minHeight:300,width:'100%',minWidth:300,}}
    src={`https://www.youtube.com/embed/`+ann.video_id}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
    }
       {ann.show_button && <div style={{width:'100%',background:'rgba(255,255,255,0.4)',position:'absolute',bottom:8,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
          <button className='btn' onClick={()=>{
             window.location.href = ann.redirect_url; 

          }} style={{background:'orange',color:'white',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{ann.button_text}</button>
        </div>}
      </div>
    }
})} */}
{/* {announcements.map((ann,index)=>{
    if(ann.position=='above_community_events'){
      return <div key={index} style={{width:'100%',position:'relative',padding:8}}>

        <img src={ann.main_image} width='100%'/>
       {ann.show_button && <div style={{width:'100%',background:'rgba(255,255,255,0.4)',position:'absolute',bottom:8,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
          <button className='btn' onClick={()=>{
             window.location.href = ann.redirect_url; 

          }} style={{background:'orange',color:'white',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{ann.button_text}</button>
        </div>}
      </div>
    }
})} */}

    <div style={{borderBottom:'2px solid orange'}}>
      <div style={{padding:5,lineHeight:'20px',color:'white',background:'orange',width:120}}>Other Articles</div>
      
    </div>
    {loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'images/loader.gif'}  width={80} />
  </div>
    }
  
    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',padding:10}}>
<div onClick={()=>{
     if(data.length>0){
      navigate('/community/'+data[3]?.slug)
}

 
  
  }}  style={{flex:1}}>
    {data.length>0 && data[3]?.main_image==null && <div className="activity"></div>}
    {data.length>0  &&<div className="load-wraper"  style={{minHeight:230,minWidth:300,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:'cover',
        backgroundImage:data[3]?.main_image!=null ? `url(${'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +data[3]?.main_image})`: 'transparent'}}>

        </div>}
       <br/>
        
         <h5 className="card-title card_title titlehover" style={{color:'black',fontSize:16}}>{data[3]?.title}</h5>
  {/* <p className="card-text">{item.article_section_main}</p> */}
    <div className='truncate-text' style={{fontSize:14,color:'gray',lineHeight:'20px',height:100}}>
       {ReactHtmlParser(data[3]?.main_section_msg)[0]} 
   
   
    </div>
</div>
<div style={{flex:1,display:'flex',flexDirection:'column'}}>
  {data.filter((t,i)=>i<7 && i>3).map((item,idx)=>{
    return(<div key={idx} onClick={()=>{
      if(data.length>0){
            navigate('/community/'+item?.slug)
      }
  
      
      }}  style={{display:'flex',flexDirection:'row',margin:3}}>
  {item.main_image==null && <div className="activity"></div>}
<div  className="load-wraper"  style={{minHeight:100,minWidth:100,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:'cover',
        backgroundImage:item.main_image!=null ? `url(${'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image})`: 'transparent'}}>

        </div>
        <div style={{margin:5,lineHeight:'20px',height:60,overflow:'hidden'}}>
        <small className="card-title card_title titlehover" style={{color:'black',}}>{item.title.trim()}</small>
        </div>

</div>)
    })}


</div>
    </div>
    {/* {announcements.map((ann,index)=>{
    if(ann.position=='below_community_events'){
      return <div key={index} style={{width:'100%',position:'relative',padding:8}}>
<br/><br/>

<p><h4>{ann.title}</h4></p>

        {ann.category!='video' ?<img src={ann.main_image} width='100%'/>:
 <iframe
 style={{minHeight:300,width:'100%',minWidth:300,}}
    src={`https://www.youtube.com/embed/`+ann.video_id}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
    }

       {ann.show_button && <div style={{width:'100%',background:'rgba(255,255,255,0.4)',position:'absolute',bottom:8,left:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
          <button className='btn' onClick={()=>{
             window.location.href = ann.redirect_url; 

          }} style={{background:'orange',color:'white',height:30,lineHeight:'30px',paddingTop:0,margin:8,marginRight:16}}>{ann.button_text}</button>
        </div>}
      </div>
    }
})} */}
    </div>

  );
};

export default Article;